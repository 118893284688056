export const caseManagmentHeader = {
    CASE_ID: "Case ID",
    CASE_LISTING_TITLE: "Case Title",
    CASE_LISTING: "Case Listing",
    ADMINSTRATED_BY: "Department Name",
    ADMIN_EMAIL: "Email Address",
    STATUS: "Status",
    FEES: "Fees",
    CUSTOMER_NAME: "Customer Name",
    SUB_ADMIN_NAME: "Department Name",
    REGION: "Country",
    PROCESS: "Process",
    FEE_MANAGEMENT: "Fee Deadline",
    CASE_STATUS: "Case Status",
    RESPONSE_STATUS: "Response Status",
    CUSTOMER_PHONE_NUMBER: "Customer’s Phone Number",
    TOTAL_EARNINGS: "Total Earnings ",
    ACTION: "Action"
};

export const customerManagementHeader = {
    CUSTOMER_ID: "Customer ID",
    CUSTOMER_NAME: "Customer Name",
    PHONE_NUMBER: "Phone Number",
    TOTAL_SPENDING: "Total Spending",
    CASE_FILED: "Cases Filed",
    ACTION: "Actions"
};

export const subAdminsHeader = {
    SUBADMIN_NAME: "Department Name",
    EMAIL: "Email Address",
    PHONE_NUMBER: "Phone Number",
    TOTAL_EARNINGS: "Total Earnings ",
    COUNTRY: "Country",
    CASE_LOAD: "Caseload",
    ACTION: "Actions"
};

export const adminsHeader = {
    ADMIN_NAME: "Sub Admin Name",
    EMAIL: "Email Address",
    PHONE_NUMBER: "Phone Number",
    COUNTRY: "Country",
    STATUS: "Status",
    ACTION: "Actions"
};

export const earningsHeader = {
    CASE_ID: "Case ID",
    CUSTOMER_NAME: "Customer Name",
    ADMIN_NAME: "Department Name",
    CREATED_AT: "Created At",
    PROCESS: "Process",
    TOTAL_EARNINGS: "Total Earnings ",
    CASE_NAME: "Cases Titles",
    ACTION: "Action",
    STEP_FEE: "Step Fee"
};

export const earningsDetailsHeader = {
    STEP_NUM: "Step Number",
    PROCESS: "Process",
    FEES: "Fees"
};

export const countriesHeader = {
    NO: "No",
    COUNTRY: "Country",
    COUNTRY_CODE: "Country code",
    STATUS: "Status",
    TMCR_DELIVERY_STATUS: "Original TMCR Delivery"
};

export const goodsServicesHeader = {
    CLASS_NO: "No.",
    GOOD_SERVICES: "Goods/Services Name",
    DESCRIPTION: "Description",
    STATUS: "Status",
    ACTION: "Action"
};
