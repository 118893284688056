import PropTypes from "prop-types";
import React from "react";
import { Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TableHead from "./TableHead";
import { Loader } from "../../components/Loader";

const DataTable = (props) => {
    const {
        config,
        data,
        onRowClick,
        onRowDrag,
        currentSortOrder,
        currentSortColumn,
        onSortClick,
        loading = false
    } = props;

    const isRowDraggable = () => {
        return onRowDrag && typeof onRowDrag === "function";
    };

    const isRowClickable = () => {
        return onRowClick && typeof onRowClick === "function";
    };

    const handleRowClick = (e, dataObj) => {
        if (isRowClickable()) {
            onRowClick(e, dataObj);
        }
    };

    const shouldShowCol = (data) => {
        return !data.hasOwnProperty("show") || data.show;
    };

    return (
        <DragDropContext onDragEnd={onRowDrag}>
            <div className="table-responsive table-min-height table-con bg-white">
                <Table className="table-centered table-nowrap bv-data-table">
                    <thead className="thead-light">
                        <tr>
                            {config.map((item, index) => {
                                return (
                                    shouldShowCol(item) && (
                                        <TableHead
                                            key={index}
                                            options={item}
                                            currentSortOrder={currentSortOrder}
                                            currentSortColumn={currentSortColumn}
                                            onSortClick={onSortClick}
                                        />
                                    )
                                );
                            })}
                        </tr>
                    </thead>
                    <Droppable droppableId="table" isDropDisabled={!isRowDraggable()}>
                        {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {data && data.length > 0 && !loading
                                    ? data.map((dataObj, dataIndex) => {
                                          return (
                                              <Draggable
                                                  // key={dataObj.Id}
                                                  key={`${dataIndex}-1`}
                                                  draggableId={`${dataObj.Id}`}
                                                  index={dataIndex}
                                                  isDragDisabled={!isRowDraggable()}
                                              >
                                                  {(provided) => (
                                                      <tr
                                                          key={dataIndex}
                                                          onClick={(e) =>
                                                              handleRowClick(e, dataObj)
                                                          }
                                                          className={`${
                                                              isRowClickable()
                                                                  ? "cursor-pointer"
                                                                  : ""
                                                          }`}
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                      >
                                                          {config.map((item, index) => {
                                                              return shouldShowCol(item) ? (
                                                                  <td
                                                                      key={index}
                                                                      className={item.className}
                                                                  >
                                                                      {item.render(
                                                                          dataObj,
                                                                          dataIndex
                                                                      )}
                                                                  </td>
                                                              ) : null;
                                                          })}
                                                      </tr>
                                                  )}
                                              </Draggable>
                                          );
                                      })
                                    : null}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </Table>
                {(!data || !data.length || loading) && (
                    <div className="table-loader-container">
                        {loading ? (
                            <Loader />
                        ) : (
                            <p className="text-muted text-center">{"No Data Available"}</p>
                        )}
                    </div>
                )}
            </div>
        </DragDropContext>
    );
};

DataTable.propTypes = {
    t: PropTypes.any,
    config: PropTypes.any,
    data: PropTypes.any,
    onRowClick: PropTypes.any,
    onRowDrag: PropTypes.any,
    currentSortOrder: PropTypes.any,
    currentSortColumn: PropTypes.any,
    onSortClick: PropTypes.any,
    loading: PropTypes.any
};

export default withTranslation()(DataTable);
