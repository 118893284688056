const METATAGS = {
    LOGIN: "Login | aiApait",
    REGISTER: "Register | aiApait",
    FORGOT_PASSWORD: "Forget Password | aiApait",
    FORGOT_VERIFY_CODE: "Forget Verify Code | aiApait",
    RESET_PASSWORD: "Reset Password | aiApait",
    DASHBOARD: "Dashboard | aiApait",
    CASE_MANAGEMENT: "Case Management | aiApait",
    CASE_DETAILS: "Case Details | aiApait",
    CUSTOMER_MANAGEMENT: "Customer Management | aiApait",
    CUSTOMER_DETAILS: "Customer Details | aiApait",
    SUB_ADMINS: "Admins | aiApait",
    SUB_ADMINS_DETAILS: "Admin Details | aiApait",
    DEPARTMENTS: "Departments | aiApait",
    DEPARTMENT_DETAILS: "Department Details | aiApait",
    EARNINGS: "Earnings | aiApait",
    EARNING_DETAILS: "Earning Details | aiApait",
    GOODS_SERVICES: "Goods&Services | aiApait",
    COUNTRIES: "Countries | aiApait",
    CHAT: "Chat | aiApait",
    SETTINGS: "Settings | aiApait",
    EDIT_PROFILE: "Edit Profile | aiApait",
    CHANGE_PASSWORD: "Change Password | aiApait"
};

export default METATAGS;
