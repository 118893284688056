import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import MetaTags from "react-meta-tags";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Label } from "reactstrap";

import { getMe } from 'store/actions';
import { CustomButton } from "components/CustomButton";
import { TextInput } from "components/Input";
import { APP_ROUTES } from "helpers/routeHelpers";
import { SuccessModalCustom } from "components/SuccessModalCustom";
import { METATAGS, preferredCountriesList,defaultCountry  } from "../../constants";
import { updateUser } from 'api/api.service';

const EditProfile = () => {

    const userData = useSelector(store => store?.userAuth?.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [resetSuccessModal, setResetSuccessModal] = useState(false);

    // formik validation for input fields
    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            username: userData?.name,
            phoneNum: `${userData?.countryCode}${userData?.phone}`
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Username")
            .min(2, 'Name should be min of 2 characters')
            .max(55, 'Name should be max of 55 chars'),
            phoneNum: Yup.string()
            .required("Enter Phone Number")
            .test("phoneNum", "Phone number is invalid", function (val) {
                console.log('val', val)
                return val && isValidPhoneNumber(val);
            })
        }),

        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    // form submission to API handling
    const handleFormSubmit = async (data) => {
        try {
            const newData = { ...data };
            setIsLoading(true);
            let countryCodeAlpha = parsePhoneNumber(newData.phoneNum).country;
            let countryCode = getCountryCallingCode(countryCodeAlpha);
            countryCode = `+${countryCode}`;
            let newPhoneNum = newData.phoneNum.split(countryCode)[1];
            newData.phone = newPhoneNum;
            newData.countryCodeAlpha = countryCodeAlpha;
            newData.countryCode = countryCode;
            newData.name = data?.username;
            delete newData.phoneNum;
            delete newData.username;
            const res = await updateUser(userData?.id, newData);
            await dispatch(getMe());
            setResetSuccessModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    // reset password modal close handling
    const modalCloseHandler = () => {
        setResetSuccessModal(false);
        history.push(APP_ROUTES.SETTINGS);
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.EDIT_PROFILE}</title>
            </MetaTags>
            <div className="bv-login-wrap mt-5 edit-profile-con mb-5">
                <form className="" onSubmit={formik.handleSubmit}>
                    <Label>User Name</Label>
                    <div className="mb-3">
                        <TextInput
                            placeholder="User Name"
                            name={"username"}
                            type="text"
                            className="hide-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                        />
                        {formik.touched.username && formik.errors.username ? (
                            <span className="error">{formik.errors.username}</span>
                        ) : null}
                    </div>

                    <Label>Phone Number</Label>
                    <div className="mb-5 pb-5">
                        <PhoneInput
                            defaultCountry={defaultCountry}
                            countryOptionsOrder={preferredCountriesList}
                            onChange={(values) =>
                                formik.setFieldValue("phoneNum", values)
                            }
                            onBlur={() => {
                                formik.setFieldTouched("phoneNum");
                            }}
                            value={formik.values.phoneNum}
                            name="phoneNum"
                            className="phone-num-con"
                        />
                        {formik.touched.phoneNum && formik.errors.phoneNum ? (
                            <span className="error">{formik.errors.phoneNum}</span>
                        ) : null}
                    </div>
                    <CustomButton
                        loading={isLoading}
                        disabled={isLoading}
                        type="submit"
                        color="primary"
                        title="Save Settings"
                        className="w-100 login-btn-typo mb-5"
                    />
                </form>
            </div>
            <SuccessModalCustom
                btnTitle="Done"
                mainContent="Profile settings have been updated successfully"
                modalActivator={resetSuccessModal}
                modalCloseHandler={modalCloseHandler}
            />
        </>
    );
};

export default EditProfile;
