import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { Label } from "reactstrap";

import { TextInput } from "components/Input";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";

import { createCountry } from "./../../api/api.service";

import { AutoComplete } from "components/AutoComplete";
import { customStyles, countriesOptions, shippingActive } from "../../constants";

import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";

const regex = /(.+)\((.+)\)\((.+)\)/;

const AddNewCountry = ({
    modalActivator = false,
    modalCloseHandler = () => {},
    modalData = {},
    selectCountryHandler = () => {}
}) => {
    const dataObj = { name: "", shippingAcive: "" };
    const [isLoading, setIsLoading] = React.useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...dataObj
        },
        validationSchema: Yup.object({
            shippingAcive: Yup.string().required("Select Shipping Status"),
            name: Yup.string().required("Select a country")
        }),
        onSubmit: (values, { resetForm }) => {
            handleFormSubmit(values, resetForm);
            // resetForm();
        }
    });

    // Extract name (before parentheses) from a string
    const extractName = (str) => {
        return str?.split("(")[0]?.trim();
    };

    // Extract countryCode (inside parentheses) from a string
    const extractCountryCode = (str) => {
        return str.split("(")[1].replace(")", "").trim();
    };

    // Process form data, extracting name and countryCode
    const processFormData = (data) => {
        const matches = data && data?.name.match(regex);

        if (matches && matches.length === 4) {
            return {
                isTrademarkDelivery: data.shippingAcive || false,
                name: `${matches[1]} (${matches[2]})`,
                countryCode: matches[3]
            };
        } else {
            return {
                isTrademarkDelivery: data.shippingAcive || false,
                name: extractName(data.name),
                countryCode: extractCountryCode(data.name)
            };
        }
    };

    // Handle success by showing a toast and closing the modal
    const handleSuccess = (res, resetForm) => {
        toast.success("Country has been created successfully!");
        modalCloseHandler("isCreated");
        resetForm();
    };

    const handleFormSubmit = async (data, resetForm) => {
        try {
            setIsLoading(true);
            const newData = processFormData(data);
            const res = await createCountry(newData);
            handleSuccess(res, resetForm);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {};

    const backBtnHandler = (e, formReset) => {
        formReset();
        modalCloseHandler();
    };
    return (
        <>
            <CustomModal
                isOpen={modalActivator}
                className="add-department-custom-modal add-country-modal"
                data={
                    <div className="text-center add-department-modal px-5 pb-4 mb-3">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="d-flex  align-items-center mb-4">
                                <img
                                    src={backIcon}
                                    alt="close modal icon"
                                    onClick={(e) => backBtnHandler(e, formik.resetForm)}
                                />
                                <p className="my-2 mx-auto">{"Add New Country"}</p>
                            </div>
                            <div className="main-header-filter-box mb-4">
                                {/* <Label className="ms-4 me-3 mb-0">Filter by</Label> */}
                                <div className="header-filter text-left">
                                    <AutoComplete
                                        customStyles={customStyles.addNewDept}
                                        onChange={(val) => formik.setFieldValue("name", val.label)}
                                        // onChange={handleChange}
                                        onBlur={() => {
                                            formik.setFieldTouched("name");
                                        }}
                                        value={countriesOptions.filter(
                                            (el) => el.label === formik.values.name
                                        )}
                                        name="name"
                                        isSearchable={true}
                                        classNamePrefix="status-header-search-admin"
                                        options={countriesOptions}
                                        placeholder="Select Country"
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <span className="error error-msg">
                                            {formik.errors.name}
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="main-header-filter-box mb-4">
                                {/* <Label className="ms-4 me-3 mb-0">Filter by</Label> */}
                                <div className="header-filter text-left">
                                    <AutoComplete
                                        customStyles={customStyles.addNewDept}
                                        onChange={(val) =>
                                            formik.setFieldValue("shippingAcive", val.value)
                                        }
                                        // onChange={handleChange}
                                        onBlur={() => {
                                            formik.setFieldTouched("shippingAcive");
                                        }}
                                        // value={shippingActive.filter(
                                        //     (el) => el.label === formik.values.shippingAcive
                                        // )}
                                        value={shippingActive.find(
                                            (el) => el.value === formik.values.shippingAcive
                                        )}
                                        name="shippingAcive"
                                        isSearchable={true}
                                        classNamePrefix="status-header-search-admin"
                                        options={shippingActive}
                                        placeholder="Select Shipping Status"
                                    />
                                    {formik.touched.shippingAcive && formik.errors.shippingAcive ? (
                                        <span className="error error-msg">
                                            {formik.errors.shippingAcive}
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <CustomButton
                                    title={"Add Country"}
                                    className="w-100 shadow-none"
                                    color="primary"
                                    loading={isLoading}
                                    disabled={isLoading}
                                    // onClick={modalCloseHandler}
                                    type={"submit"}
                                />
                            </div>
                        </form>
                    </div>
                }
            />
        </>
    );
};

AddNewCountry.propTypes = {
    modalActivator: PropTypes.bool,
    modalCloseHandler: PropTypes.func,
    modalData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    selectCountryHandler: PropTypes.func
};

export default AddNewCountry;
