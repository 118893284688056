import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from 'react-redux'
import { Col, Row, Label } from "reactstrap";
import MetaTags from "react-meta-tags";

import { HeaderWithButton } from "components/HeaderWithButton";
import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { Sorting } from "components/Sorting";
import { SearchBox } from "components/SearchBox";
import { AutoComplete } from "components/AutoComplete";

import { GetCustomerById, updateUser } from "api/api.service";
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";

import detailsIcon from "../../assets/images/aiAPaiT/Table/view-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";
import {
    CaseStatus,
    filterOptionsCustomerManagement,
    sortingOptions,
    caseManagmentHeader,
    customStyles,
    caseStatusOptions,
    responseStatusOptions,
    processStatusOptions,
    countriesOptions,
    METATAGS,
    stepsName,
    UserRoleType
} from "../../constants";

const Details = () => {

    // checking for api call count
    let APIHitCount = 0;

    const { id } = useParams();
    const userType = useSelector(store => store?.userAuth?.user?.type)
    const [userData, setUserData] = useState({});
    const [isSuspendUserLoading, setIsSuspendUserLoading] = useState(false);
    const [filters, setFilters] = useState({
        filterType: "",
        statusValue: []
    });

    // finding filter options for user selected option
    const sortingOptionsEvaluated =
        filters.filterType === "countryName"
            ? countriesOptions
            : filters.filterType === "trademarkStatus"
            ? caseStatusOptions
            : filters.filterType === "trademarkStep"
            ? processStatusOptions
            : responseStatusOptions;

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        updatePageOnFilterChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: GetCustomerById,
        params: {
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumns: filters?.filterType }),
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumnsValue: filters?.statusValue?.value })
        },
        deps: [filters.statusValue],
        dataId: id
    });

    // updating user data only as not refetching again
    useEffect(() => {
        if (
            data &&
            Object.keys(data).length > 0 &&
            APIHitCount === 0 &&
            Object.keys(userData).length === 0
        ) {
            setUserData(data);
            APIHitCount = 1;
        }
    }, [data]);

    // handle filter select on change
    const handleFilterTypeSelect = (val, valueType) => {
        if (val) {
            if(valueType){
                setFilters({ ...filters, filterType: val?.value, statusValue: "" })
            }else{
                updatePageOnFilterChange()
                setFilters({ ...filters, statusValue: val });
            }
        }
    };

    // customer suspension handling
    const handleCustomerSuspend = async () => {
        try {
            setIsSuspendUserLoading(true);
            let status = data?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
            await updateUser(id, { status: status });
            toast.success(
                `Customer has been ${
                    status === "ACTIVE" ? "unsuspended" : "suspended"
                } successfully`
            );
            request();
        } finally {
            setIsSuspendUserLoading(false);
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CUSTOMER_DETAILS}</title>
            </MetaTags>
            <HeaderWithButton
                isCustomerDetails={true}
                data={userData}
                handleButtonClick={handleCustomerSuspend}
                isUserSuspended={data?.status}
                isSuspensionLoading={isSuspendUserLoading}
                isButton={userType === 'ADMIN'}
            />
            <Row className="p-3 pt-0 case-management-con">
                <Col md={12}>
                    <div className="bg-white d-flex align-items-center p-3 listing-header-con">
                        <SearchBox
                            iconClass="main-header-search-icon"
                            className="main-header-search-box main-header-search-box-modified"
                            placeholder="Search here"
                            onChange={handleSearchChange}
                            value={searchText}
                        />
                        <div className="d-flex  main-header-filter-box align-items-center main-header-type-filter-modified">
                            <Label className="ms-4 me-3 mb-0">Filter Type</Label>
                            <div className="header-filter">
                                <AutoComplete
                                    customStyles={customStyles.WarehouseIM}
                                    isSearchable={false}
                                    onChange={(val) => handleFilterTypeSelect(val, "valueType")}
                                    classNamePrefix="status-header-search-admin"
                                    options={filterOptionsCustomerManagement}
                                    placeholder="Select Filter Type"
                                />
                            </div>
                        </div>
                        {filters?.filterType !== "" && (
                            <div className="d-flex  main-header-filter-box  align-items-center main-header-con-modified">
                                <Label className="ms-4 me-3 mb-0 label-filterBy">Filter by</Label>
                                <div className="header-filter">
                                    <AutoComplete
                                        customStyles={customStyles.WarehouseIM}
                                        isSearchable={
                                            filters.filterType === "countryName" ? true : false
                                        }
                                        onChange={(val) => handleFilterTypeSelect(val)}
                                        value={filters.statusValue}
                                        classNamePrefix="status-header-search-admin"
                                        options={sortingOptionsEvaluated}
                                        placeholder="Select From List"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
                <Col md={12}>
                    <DataTable
                        data={data?.customerDetails}
                        loading={isFetching}
                        config={[
                            {
                                title: caseManagmentHeader.CASE_ID,
                                className: "cm-case-id",
                                render: (data) => data?.caseId || "-"
                            },
                            {
                                title: caseManagmentHeader.CASE_LISTING_TITLE,
                                className: "bold-font cm-cust-name width-wrap-text case-name-col",
                                render: (data) => data?.caseTitle || "-"
                            },
                            {
                                title: caseManagmentHeader.REGION,
                                className: "cm-region",
                                render: (data) => data?.countryName || "-"
                            },
                            ...(userType !== UserRoleType.SubAdmin ? [{
                                title: caseManagmentHeader.ADMINSTRATED_BY,
                                className: "cm-subadmin-name",
                                render: (data) => data?.departmentName || "-"
                            }]: []),
                            {
                                title: caseManagmentHeader.ADMIN_EMAIL,
                                className: "cm-subadmin-name",
                                render: (data) => data?.email || "-"
                            },
                            {
                                title: caseManagmentHeader.PROCESS,
                                className: "bold-font cm-process ",
                                render: (data) =>
                                    stepsName.getDisplayTextKey(data?.trademarkStep) || "-"
                            },
                            {
                                title: caseManagmentHeader.STATUS,
                                className: "cm-status",
                                render: (data) => {
                                    return (
                                        <div
                                            className={`${CaseStatus.getLabelClass(
                                                data?.trademarkStatus
                                            )} status-rest `}
                                        >
                                            <p>
                                                {CaseStatus.getDisplayTextKey(
                                                    data?.trademarkStatus
                                                ) || "-"}
                                            </p>
                                        </div>
                                    );
                                }
                            },
                            {
                                title: caseManagmentHeader.FEES,
                                className: "bold-font cm-process ",
                                render: (data) => `$${data?.fees}` || "-"
                            },
                            {
                                title: caseManagmentHeader.ACTION,
                                className: "cm-action",
                                render: (data) => (
                                    <Link
                                        to={`${APP_ROUTES.EARNINGS_DETAILS}/${data?.id}`}
                                        className=" pointer "
                                    >
                                        <img src={detailsIcon} alt="details icon" />
                                    </Link>
                                )
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            rowLimit={pageSize}
                            className="pagination-contain "
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Details;
