import React from 'react';
import PropTypes from "prop-types"
import { Label } from 'reactstrap'
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextInput } from "components/Input";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";
import passIcon from "assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "assets/images/aiAPaiT/show-lock-icon.svg";

const ResetPasswordModal = ({ 
  modalActivator= false,
  handlePasswordSubmit= () => {},
  modalCloseHandler=() =>{}
}) => {

  const [passwordFieldType, setPasswordFieldType] = React.useState(false);
  const [resetPasswordFieldType, setResetPasswordFieldType] = React.useState(false);


  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
      .required("Please Enter Your New Password")
      .min(6, "Password should be minimum 6 characters long")
      .max(25, "Password should be between 6 to 25 characters")
      .test(
        "regex",
        "Password must contain characters, special characters, and numbers",
        (val) => {
            let regExp = new RegExp(
                "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
            );

            return regExp.test(val);
        }
      ),
      confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword")],
        "Please make sure your passwords match"
        )
        .required("Please Enter Your Confirm Password")
      }),
    onSubmit: (values, {resetForm}) => {
        handlePasswordSubmit(values);
        resetForm();
    }
  });

  const passwordFieldChangeHandler = () => {
    setPasswordFieldType(!passwordFieldType)
}
const resetPasswordFieldChangeHandler = () => {
  setResetPasswordFieldType(!resetPasswordFieldType)
}

  return(
    <>
      <CustomModal
        isOpen={modalActivator}
        className="add-department-custom-modal "
        onClose={modalCloseHandler}
        modalCloseIconClass="close-modal-icon pb-0"
        modalHeaderClass="header-padding"

        data={
            <div className=" add-department-modal px-5 pb-4 mb-3">
              <div className='d-flex  align-items-center mb-4'>
                <p className="my-0 mx-auto">
                Change Password  
                </p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <Label>Type New Password</Label>
                <div className='mb-3'>
                  <TextInput
                  placeholder="Enter New Password"
                  className="dept-name-ip "
                  name="newPassword"
                  type={!passwordFieldType ? "password" : "text"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                   iconFile={!passwordFieldType ? passIcon : showPassIcon}
                  appendIcon={true}
                  iconClickHandler={passwordFieldChangeHandler}
                  />
                   {formik.touched.newPassword && formik.errors.newPassword ? (
                        <span className="error">{formik.errors.newPassword}</span>
                    ) : null}
                </div>
                <Label>Re-type New Password</Label>
                <div className='mb-4'>
                  <TextInput
                  placeholder="Enter New Password"
                  className="dept-name-ip "
                  name="confirmPassword"
                  type={!resetPasswordFieldType ? "password" : "text"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                   iconFile={!resetPasswordFieldType ? passIcon : showPassIcon}
                  appendIcon={true}
                  iconClickHandler={resetPasswordFieldChangeHandler} />
                   {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <span className="error">{formik.errors.confirmPassword}</span>
                    ) : null}
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <CustomButton
                    title={"Change Password"}
                    className="w-100 shadow-none"
                    color="primary"
                    type="submit"
                  />
                </div>    
              </form>
            </div>
        }
      />
    </>
  )
}

ResetPasswordModal.propTypes = {
  modalActivator: PropTypes.bool,
  handlePasswordSubmit: PropTypes.func,
}

export default ResetPasswordModal;