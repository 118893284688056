import { Get, Put, Post, Delete } from "./api.helper";
import { store } from "store";
import Config from "Config";
import { appendQueryParams } from "utils/urlUtils";

// **** AUTH APIS
const Login = (bodyData) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false
    });
};

const GetMe = () => {
    return Get({ url: "/user" });
};

const ForgetPasswordApi = (bodyData) => {
    return Post({ url: "/auth/forget-password", body: bodyData });
};

const ForgetPasswordVerification = (bodyData) => {
    return Post({
        url: "/auth/forget-password/verification",
        body: bodyData
    });
};

const ResendOtp = (bodyData) => {
    return Post({
        url: "/auth/resend-otp",
        body: bodyData
    });
};

const ResetPassword = (bodyData) => {
    return Post({ url: "/auth/reset-password", body: bodyData });
};

const VerifyUser = (bodyData) => {
    return Post({
        url: "/",
        body: bodyData,
        isAuthorized: false
    });
};

const SignUp = (bodyData) => {
    return Post({
        url: "/auth/signup",
        body: bodyData,
        isAuthorized: false
    });
};

const updateUser = (id, params = {}, abortSignal = null) => {
    return Put({
        url: `/auth/${id}`,
        body: params,
        abortSignal
    });
};

// *** CASEMANAGEMENT APIS *** //
const getAllCaseManagement = (params = {}, abortSignal = null) => {
    return Get({
        url: "/trademark/trademarks",
        params,
        abortSignal
    });
};

const GetCaseById = (caseId, params = {}, abortSignal = null) => {
    return Get({
        url: `/trademark/trademark/${caseId}`,
        params,
        abortSignal
    });
};

const createStepTwo = (caseId, params = {}, abortSignal = null) => {
    return Post({
        url: `/trademark/poa-application/${caseId}`,
        body: params,
        abortSignal
    });
};

const createStepThree = (caseId, params = {}, abortSignal = null) => {
    return Post({
        url: `/trademark/publication/${caseId}`,
        body: params,
        abortSignal
    });
};

const createStepFour = (caseId, params = {}, abortSignal = null) => {
    return Post({
        url: `/trademark/registration/${caseId}`,
        body: params,
        abortSignal
    });
};

const createStepFive = (caseId, params = {}, abortSignal = null) => {
    return Post({
        url: `/trademark/tmcr-download/${caseId}`,
        body: params,
        abortSignal
    });
};

const createRejection = (params = {}, abortSignal = null) => {
    return Post({
        url: `/trademark/trademark-step-approval`,
        body: params,
        abortSignal
    });
};

const tmcrDispatch = (id, params = {}, abortSignal = null) => {
    return Put({
        url: `/trademark/tmcr-dispatch/${id}`,
        body: params,
        abortSignal
    });
};

const updateCaseManagement = (id, params = {}, abortSignal = null) => {
    return Put({
        url: `/goodsType/${id}`,
        body: params,
        abortSignal
    });
};

//*** DASHBOARD APIS ***//
const getDashboardTrademark = (params = {}, abortSignal = null) => {
    return Get({
        url: "/trademark/trademarks?limit=3",
        params,
        abortSignal
    });
};

const getGraphData = (params = {}, abortSignal = null) => {
    return Post({
        url: `/earnings/dashboard-graph`,
        body: params,
        abortSignal
    });
};

//*** CUSTOMER APIS ***//

const getAllCustomers = (params = {}, abortSignal = null) => {
    return Get({
        url: "/user/customers",
        params,
        abortSignal
    });
};

const GetCustomerById = (params = {}, abortSignal = null, customerId) => {
    return Get({
        url: `/trademark/customer-details/${customerId}`,
        params,
        abortSignal
    });
};

//*** EARNINGS APIS ***//

const getAllEarnings = (params = {}, abortSignal = null) => {
    return Get({
        url: "/earnings/earnings-web",
        params,
        abortSignal
    });
};

const GetEarningById = (earningId) => {
    return Get({ url: `/earnings/${earningId}` });
};

const GetAllEarningsCSV = () => {
    return Get({ url: `/earnings/earnings-csv` });
};

const GetAllEarningsReceipt = (caseId) => {
    return Get({ url: `/earnings/receipt/${caseId}` });
};

const dashboardEarnings = (params = {}, abortSignal = null) => {
    return Get({
        url: "/earnings/dashboard-count",
        params,
        abortSignal
    });
};

// *** COUNTRIES APIS *** //
const getAllCountries = (params = {}, abortSignal = null) => {
    return Get({
        url: "/country/country",
        params,
        abortSignal
    });
};

const getAllActiveUnassignedCountries = (params = {}, abortSignal = null) => {
    return Get({
        url: "/country/available-country",
        params,
        abortSignal
    });
};

const createCountry = (params = {}, abortSignal = null) => {
    return Post({
        url: "/country/country",
        body: params,
        abortSignal
    });
};

const updateCountry = (id, params = {}, abortSignal = null) => {
    return Put({
        url: `/country/${id}`,
        body: params,
        abortSignal
    });
};

// *** GOODSSERVICES APIS *** //
const getAllGoodsServices = (params = {}, abortSignal = null) => {
    return Get({
        url: "/goodsType/goodsType",
        params,
        abortSignal
    });
};

const createGoodsServices = (params = {}, abortSignal = null) => {
    return Post({
        url: `/goodsType/goodsType`,
        body: params,
        abortSignal
    });
};

const updateGoodsServices = (id, params = {}, abortSignal = null) => {
    return Put({
        url: `/goodsType/${id}`,
        body: params,
        abortSignal
    });
};

//CHATS

const getAllChats = (params = {}, abortSignal = null) => {
    return Get({
        url: "/chat/chats",
        params,
        abortSignal
    });
};

const getChatListingById = (id, params = {}, abortSignal = null) => {
    return Get({
        url: `/chat/${id}`,
        params,
        abortSignal
    });
};

const getChatById = (id, params = {}, abortSignal = null) => {
    return Get({
        url: `/chat/${id}/messages`,
        params,
        abortSignal
    });
};

// *** ADMIN APIS *** //
const getAllAdmins = (params = {}, abortSignal = null) => {
    return Get({
        url: "/user/admins",
        params,
        abortSignal
    });
};

const CreateAdmin = (bodyData) => {
    return Post({
        url: "/user/create-admin",
        body: bodyData,
        isAuthorized: true
    });
};

const deleteUser = (id) => {
    return Delete({
        url: `/auth/${id}`
    });
};

// *** DEPARTMENT APIS *** //
const getAllDepartments = (params = {}, abortSignal = null) => {
    return Get({
        url: "/user/sub-admins",
        params,
        abortSignal
    });
};

const GetDepartmentById = (params = {}, abortSignal = null, departmentId) => {
    return Get({
        url: `/trademark/department-trademarks/${departmentId}`,
        params,
        abortSignal
    });
};

const getFirstStepFee = (params = {}, abortSignal = null) => {
    return Get({
        url: "/payment/fees",
        params,
        abortSignal
    });
};

const updateFirstStepFee = (params = {}, abortSignal = null) => {
    return Put({
        url: `/payment/fees`,
        body: params,
        abortSignal
    });
};

//notification
const GetNotification = (params = {}, abortSignal = null) => {
    return Get({
        url: `/trademark/notifications`,
        params,
        abortSignal
    });
};

const ReadNotification = (notificationId) => {
    return Put({
        url: `/trademark/notification-status/${notificationId}`
    });
};

const GetReadNotificationCount = (params = {}) => {
    return Get({
        url: "/trademark/notifications-count",
        params
    });
};

const GetPdfReport = (abortSignal = null) => {
    const token = store.getState().userAuth.user.Token;

    const url = `${Config.env().API_URL}/earnings/earnings-web-csv`;

    return fetch(url, {
        headers: {
            authorization: token,
            "Content-Type": "text/csv"
        },
        responseType: "blob",
        method: "GET"
    })
        .then((res) => Promise.resolve(res))
        .catch((err) => {});
};

export {
    Login,
    GetMe,
    ForgetPasswordApi,
    ForgetPasswordVerification,
    ResendOtp,
    ResetPassword,
    VerifyUser,
    updateUser,
    SignUp,
    getDashboardTrademark,
    getGraphData,
    getAllCustomers,
    GetCustomerById,
    tmcrDispatch,
    createRejection,
    createStepTwo,
    createStepThree,
    createStepFour,
    createStepFive,
    getAllEarnings,
    GetAllEarningsReceipt,
    GetEarningById,
    GetAllEarningsCSV,
    dashboardEarnings,
    getAllCountries,
    getAllActiveUnassignedCountries,
    createCountry,
    updateCountry,
    getAllGoodsServices,
    createGoodsServices,
    updateGoodsServices,
    getAllCaseManagement,
    GetCaseById,
    getAllDepartments,
    GetDepartmentById,
    getFirstStepFee,
    updateFirstStepFee,
    getAllChats,
    getChatListingById,
    GetPdfReport,
    getChatById,
    GetNotification,
    ReadNotification,
    GetReadNotificationCount,
    getAllAdmins,
    CreateAdmin,
    deleteUser
};
