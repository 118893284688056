import React, { useState } from "react";
import PropTypes from 'prop-types';
import moment from 'moment' 
import {
  Col,
  Row,
  Card,
  AccordionBody,
  Label,
  AccordionItem,
  UncontrolledAccordion
} from "reactstrap";
import { TextInput } from "components/Input";
import { CustomAccordionHeader } from "./Accordion";
import TrademarkDetail from './TrademarkDetail';
import DocumentViewerModal from "./DocumentViewerModal";

import ESignatureIcon from "../../assets/images/aiAPaiT/case-mangement/e-signature-icon.png";
import DateIcon from '../../assets/images/aiAPaiT/case-mangement/date-icon.svg';
import { accordionStatus, dateFormat } from "./Constants";

export default function PublicationAwaiting ({
  defaultOpenAccord=[],
  title="",
  targetId="",
  processNO,
  data_description}){

    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false)
    const [pdfDataLink, setPdfDataLink] = useState("");

    // handling input field on change
    const handleFeeChange = (e) => {
        console.log(e)
    }

    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl)
        setIsPdfViewerModalActive(true)
    };
    // pdf/image document view modal close handling
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("")
        setIsPdfViewerModalActive(false)
    }

  return(
  <>
    <div className="case-detail-accordian-signature-con">
    <UncontrolledAccordion 
      defaultOpen={defaultOpenAccord}
      stayOpen>
          <AccordionItem>
              <CustomAccordionHeader
                  containerClassName="case-detail-accordian-container"
                  innerClassName="case-detail-accordian-header"
                  subContainerClassName="case-detail-accordian-header-status"
                  headerCount={processNO}
                  headerText={title}
                  status={accordionStatus.OPEN}
                  targetId={targetId}
              />

              <AccordionBody
                  accordionId={targetId}
                  className="case-detail-accordian-body-con"
              >
                  <Card>
                  {data_description && 
                  data_description?.documents?.length > 0 && 
                  Object.keys(data_description).length > 0 ? 
                  (<>
                      <Row>                          
                          <Col md={6}>
                            <div className="case-detail-accordian-signature-body">
                            {"Trademark Publication Form"}
                            </div>
                            <TrademarkDetail
                                documentData={ 
                                data_description?.documents[0].media}
                                documentViewerHandler={documentViewerHandler}
                            />
                         </Col> 
                         <Col md={12}>
                            <div  className="case-detail-accordian-body">
                                <div>Step Fee</div>
                                <p>
                                  {`$${data_description?.amount}`}
                                </p>
                            </div>
                            <div className='bg-white p-3 pt-4 case-details-accordian-date-field'>
                                <Label>Fee Deadline</Label>
                                <div className="mb-3">
                                    <TextInput
                                        name={"feesDeadline"}
                                        type="date"
                                        className="hide-icon"
                                        onChange={handleFeeChange}
                                        value={moment(data_description?.expiryDate).format(dateFormat.yearMonthDate)}
                                        autoFocus
                                        iconFile={DateIcon}
                                        disabled
                                    />
                                </div>                  
                            </div> 
                          </Col>                                                  
                      </Row>
                      <hr />
                      <Row>
                          <div className="e-signature-footer-btn">
                              <img src={ESignatureIcon} height={40} width={40} />
                              <div className="accordian-body-footer-text">
                                  Case is in progress and waiting for customer
                                  response
                              </div>
                          </div>
                      </Row>
                    </>) : (<><p className="data-not-found-message">Data Not Found</p></>)}                                                                                   
                  </Card>
                  <DocumentViewerModal
                    modalActivator={isPdfViewerModalActive}
                    modalCloseHandler={pdfViewerModalCloseHandler}
                    modalData={pdfDataLink}
                    isDataFetching={false}
                    />
              </AccordionBody>
          </AccordionItem>
    </UncontrolledAccordion>
  </div>
  </>
  )
}

// handling prop types
PublicationAwaiting.propTypes = {
  openAccord: PropTypes.string,
  toggleButtonHandler: PropTypes.func,
  title: PropTypes.string,
  targetId: PropTypes.string,
  accordId: PropTypes.string,
  processNO: PropTypes.string
}