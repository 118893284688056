import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { APP_ROUTES } from "../../helpers/routeHelpers";

//icons
import dashboardIcon from "../../assets/images/aiAPaiT/sidebar/dashboard.png";
import caseMangementIcon from "../../assets/images/aiAPaiT/sidebar/case-management.png";
import customerManagementIcon from "../../assets/images/aiAPaiT/sidebar/customer-management.png";
import subAdminIcon from "../../assets/images/aiAPaiT/sidebar/sub-admin.png";
import adminIcon from "../../assets/images/aiAPaiT/sidebar/admin-management.png";

import earningsIcon from "../../assets/images/aiAPaiT/sidebar/earnings.png";
import countriesIcon from "../../assets/images/aiAPaiT/sidebar/countries.png";
import goodsServicesIcon from "../../assets/images/aiAPaiT/sidebar/goods-services.png";
import chatsIcon from "../../assets/images/aiAPaiT/sidebar/chats.png";
import settingIcon from "../../assets/images/aiAPaiT/sidebar/setting.png";

import "./SidebarContent.scss";

const SidebarContent = (props) => {
    const userObj = useSelector((store) => store?.userAuth?.user?.type);

    const ref = useRef();
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        const pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            let matchingMenuItem = null;
            const ul = document.getElementById("side-menu");
            const items = ul.getElementsByTagName("a");
            for (let i = 0; i < items.length; ++i) {
                // if (pathName === items[i].pathname) {
                if (pathName.includes(items[i].pathname)) {
                    matchingMenuItem = items[i];
                    break;
                }
                // }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [props.location.pathname]);

    useEffect(() => {
        ref.current.recalculate();
    });

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }

    return (
        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {/* <li className="menu-title">{props.t("Menu")} </li> */}

                        <li>
                            <Link to={APP_ROUTES.DASHBOARD}>
                                <img src={dashboardIcon} className=" pe-2 py-1" />
                                <span>Dashboards</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={APP_ROUTES.CASE_MANAGEMENT}>
                                <img src={caseMangementIcon} className=" pe-2 py-1" />
                                <span>Case Management</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={APP_ROUTES.CUSTOMER_MANAGEMENT}>
                                <img src={customerManagementIcon} className=" pe-2 py-1" />
                                <span>Customer Management</span>
                            </Link>
                        </li>

                        {userObj === "ADMIN" || userObj === "SUPER_ADMIN" ? (
                            <li>
                                <Link to={APP_ROUTES.SUB_ADMINS}>
                                    <img src={subAdminIcon} className=" pe-2 py-1" />
                                    <span>Departments</span>
                                </Link>
                            </li>
                        ) : null}
                        <li>
                            <Link to={APP_ROUTES.EARNINGS}>
                                <img src={earningsIcon} className=" pe-2 py-1" />
                                <span>Earnings</span>
                            </Link>
                        </li>
                        {userObj === "ADMIN" || userObj === "SUPER_ADMIN" ? (
                            <li>
                                <Link to={APP_ROUTES.COUNTRIES}>
                                    <img src={countriesIcon} className=" pe-2 py-1" />
                                    <span>Countries</span>
                                </Link>
                            </li>
                        ) : null}
                        {/* <li>
                            <Link to={APP_ROUTES.GOODS_SERVICES}>
                                <img src={goodsServicesIcon} className=" pe-2 py-1" />
                                <span>Goods/Services</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to={APP_ROUTES.CHATS}>
                                <img src={chatsIcon} className=" pe-2 py-1" />
                                <span>Chats</span>
                            </Link>
                        </li>
                        {userObj === "SUPER_ADMIN" ? (
                            <li>
                                <Link to={APP_ROUTES.ADMIN}>
                                    <img
                                        src={adminIcon}
                                        className=" pe-2 py-1"
                                        width={"100%"}
                                        height={"100&"}
                                    />

                                    <span>Sub Admins</span>
                                </Link>
                            </li>
                        ) : null}
                        <li>
                            <Link to={APP_ROUTES.SETTINGS}>
                                <img src={settingIcon} className=" pe-2 py-1" />
                                <span>Settings</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
