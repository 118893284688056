import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";

import { CreateAdmin, updateUser, getAllActiveUnassignedCountries } from "./../../api/api.service";
import { TextInput } from "components/Input";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";

import { AutoComplete } from "components/AutoComplete";
import { customStyles, preferredCountriesList, defaultCountry } from "../../constants";

import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";

const initailData = {
    email: "",
    name: "",
    phoneNum: ""
};

const AddNewAdmin = ({
    modalActivator = false,
    modalCloseHandler = () => {},
    modalData = {},
    dataChangeHandler = () => {},
    isEdit = false,
    phoneNum = "",
    refetchData
}) => {
    const dataObj = isEdit ? modalData : initailData;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isCountryListLoading, setIsCountryListLoading] = React.useState(false);
    const [isAdminCreated, setIsAdminCreated] = React.useState(false);

    // input fields validation using formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...dataObj
        },
        validationSchema: Yup.object({
            ...(!isEdit && {
                email: Yup.string()
                    .email("Enter a valid email address")
                    .required("Enter email address")
                    .min(6, "Too short email!")
                    .max(50, "Too long email!")
                    .matches(
                        /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
                        "Enter valid email address"
                    )
            }),
            name: Yup.string()
                .required("Enter name")
                .min(3, "Too short name!")
                .max(50, "Too long name!"),

            ...(!isEdit && {
                phoneNum: Yup.string()
                    .required("Enter Phone Number")
                    .test("phoneNum", "Phone number is invalid", function (val) {
                        return val && isValidPhoneNumber(val);
                    })
            })
        }),
        onSubmit: (values, { resetForm }) => {
            handleFormSubmit(values, resetForm);
        }
    });

    // form submission to BE hanlding
    const handleFormSubmit = async (data, resetForm) => {
        try {
            const newData = { ...data };
            setIsLoading(true);
            let countryCodeAlpha;
            let countryCode;
            let newPhoneNum;

            countryCodeAlpha = parsePhoneNumber(newData.phoneNum).country;
            countryCode = getCountryCallingCode(countryCodeAlpha);
            countryCode = `+${countryCode}`;
            newPhoneNum = newData.phoneNum.split(countryCode)[1];
            newData.phone = newPhoneNum;
            newData.countryCode = countryCode;

            let bodyData = {
                email: newData.email,
                name: newData.name,
                phone: newData.phone,
                countryCode: newData.countryCode
            };

            let editableData = {
                name: newData.name,
                phone: newData.phone,
                countryCode: newData.countryCode
            };

            const res = isEdit
                ? await updateUser(modalData?.userId, editableData)
                : await CreateAdmin(bodyData);
            toast.success(`Sub Admin has been ${isEdit ? "updated" : "created"} successfully!`);
            setIsAdminCreated(!isAdminCreated);
            modalCloseHandler();
            refetchData();
            resetForm();
        } finally {
            setIsLoading(false);
        }
    };

    // back button click hanlding
    const handleBackBtn = (e, resetForm) => {
        resetForm();
        modalCloseHandler();
    };
    return (
        <>
            <CustomModal
                isOpen={modalActivator}
                className="add-department-custom-modal"
                data={
                    !isCountryListLoading ? (
                        <div className=" add-department-modal px-5 pb-4 mb-3">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="d-flex  align-items-center mb-4">
                                    <img
                                        src={backIcon}
                                        alt="close modal icon"
                                        onClick={(e) => handleBackBtn(e, formik.resetForm)}
                                    />
                                    <p className="my-2 mx-auto">
                                        {isEdit ? "Edit Sub Admin" : "Add New Sub Admin"}
                                    </p>
                                </div>
                                <div className="mb-3">
                                    <TextInput
                                        placeholder="Sub Admin Name"
                                        className="dept-name-ip"
                                        type="text"
                                        name="name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <span className="error">{formik.errors.name}</span>
                                    ) : null}
                                </div>
                                {!isEdit && (
                                    <div className="mb-3">
                                        <TextInput
                                            placeholder="Email Address"
                                            className="dept-name-ip "
                                            type="email"
                                            name="email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <span className="error">{formik.errors.email}</span>
                                        ) : null}
                                    </div>
                                )}

                                <div className="mb-3">
                                    <div>
                                        <PhoneInput
                                            defaultCountry={defaultCountry}
                                            countryOptionsOrder={preferredCountriesList}
                                            onChange={(values) =>
                                                formik.setFieldValue("phoneNum", values)
                                            }
                                            onBlur={() => {
                                                formik.setFieldTouched("phoneNum");
                                            }}
                                            value={formik.values.phoneNum}
                                            name="phoneNum"
                                            className="phone-num-con"
                                        />
                                        {formik.touched.phoneNum && formik.errors.phoneNum ? (
                                            <span className="error">{formik.errors.phoneNum}</span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                    <CustomButton
                                        title={isEdit ? "Save Changes" : "Add Sub Admin"}
                                        className="w-100 shadow-none"
                                        color="primary"
                                        loading={isLoading}
                                        disabled={isLoading}
                                        type="submit"
                                    />
                                </div>
                            </form>
                        </div>
                    ) : (
                        <></>
                    )
                }
            />
        </>
    );
};

// proptypes handling
AddNewAdmin.propTypes = {
    modalActivator: PropTypes.bool,
    modalCloseHandler: PropTypes.func,
    modalData: PropTypes.object,
    dataChangeHandler: PropTypes.func,
    selectCountryHandler: PropTypes.func,
    isEdit: PropTypes.bool,
    phoneNum: PropTypes.string
};

export default AddNewAdmin;
