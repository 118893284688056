import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Col,
    Row,
    Card,
    AccordionBody,
    Label,
    AccordionItem,
    UncontrolledAccordion
} from "reactstrap";
import { finalizeMediaUtil, initializeMediaUtil, uploadOnS3 } from "utils/mediaUtils";
import { removeSpaceUnderscoreCharacterFromString } from "utils/commonUtils";
import { TextInput } from "components/Input";
import { CustomAccordionHeader } from "./Accordion";

import { ImageUpload } from "components/ImageUpload";
import { ImageTypes } from "../../constants";
import { CustomButton } from "components/CustomButton";

import { createStepThree } from "api/api.service";
import UploadLogo from "../../assets/images/aiAPaiT/case-mangement/upload-logo.png";

import DateIcon from "../../assets/images/aiAPaiT/case-mangement/date-icon.svg";
import FormikErrorText from "components/FormikErrorText/FormikErrorText";
import {
    accordionStatus,
    buttonTitle,
    dateFormat,
    documentTypePayload,
    fieldRequirementMessage,
    responseMessages
} from "./Constants";

const KB_CONVERTER = 1024;

export default function Publication({
    defaultOpenAccord = [],
    title = "",
    targetId = "",
    processNO,
    refetchStepsAPIHandler,
    stepSubmissionStepUpdater = () => {}
}) {
    const mountedRef = useRef(true);
    let dataObj = {};
    const params = useParams();
    const [trademarkPublicationMedia, setTrademarkPublicationMedia] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [errorType, setErrorType] = useState(0);
    const [isSubmittingStepThree, setIsSubmittingStepThree] = useState(false);

    // formik validation for input fields
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            stepFees: "",
            feeDeadline: ""
        },
        validationSchema: Yup.object({
            stepFees: Yup.number()
                .integer()
                .required(fieldRequirementMessage.STEP_FEE)
                .min(1, fieldRequirementMessage.STEP_FEE_MIN_LIMIT)
                .max(1000, fieldRequirementMessage.STEP_FEE_MAX_LIMIT),
            feeDeadline: Yup.date().required(fieldRequirementMessage.DATE_FIELD)
        }),
        onSubmit: (values) => {
            dataObj.amount = values.stepFees;
            dataObj.expiryDate = values.feeDeadline;
            handleStepPublicationSubmission();
        }
    });

    // file uploading
    const FileUploadHander = (file) => {
        let imageError = undefined;
        let fileName = undefined;
        let image = "";
        if (file) {
            const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase();
            let fileSize = file && file?.size;
            fileSize = fileSize / KB_CONVERTER;
            if (!ImageTypes.includes(uploadedFileType)) {
                imageError = responseMessages.FILE_TYPE_ERROR;
                fileName = undefined;
                image = "";
            } else {
                fileName = removeSpaceUnderscoreCharacterFromString(file?.name);
                imageError = "";
                image = file;
                image.fileName = fileName;
            }
        }
        return { imageError, image, fileName };
    };

    // handle file input change
    const handleFileChange = (e, imageObjUpdater) => {
        e.preventDefault();
        let file = e.target.files[0];
        const { image, imageError, fileName } = FileUploadHander(file);
        imageObjUpdater({ image, imageError, fileName });
        setErrorType(0);
    };

    // hanlde remove image
    const removeImage = (imageObjUpdater) => {
        imageObjUpdater({ image: "", imageError: undefined, fileName: undefined });
        setErrorType(1);
    };

    // media upload on server
    const handleMediaUpload = async (file) => {
        return initializeMediaUtil(file).then(async (res) => {
            const credentials = res;
            await uploadOnS3(file, credentials);
            return await finalizeMediaUtil(credentials?.mediaId);
        });
    };

    // handle Form Submission using API on Backend
    const handleStepPublicationSubmission = async () => {
        if (trademarkPublicationMedia.image === "") {
            setErrorType(1);
            return;
        } else {
            let payloadObj = { ...dataObj };
            payloadObj.amount = Number(dataObj?.amount);
            payloadObj.expiryDate = moment(dataObj.expiryDate).format(dateFormat.expiryDate);
            payloadObj.documents = [];
            setIsSubmittingStepThree(true);
            stepSubmissionStepUpdater();
            if (trademarkPublicationMedia.image) {
                let imageRes = await handleMediaUpload(trademarkPublicationMedia.image);
                payloadObj.documents.push({
                    documentType: documentTypePayload.TRADEMARK_PUBLICATION,
                    mediaId: imageRes.id
                });
            }
            createStepThree(params?.id, payloadObj)
                .then((res) => {
                    toast.success(responseMessages.SUCCESS_PUBLICATION);
                    stepSubmissionStepUpdater();
                    refetchStepsAPIHandler();
                })
                .catch((err) => {
                    setIsSubmittingStepThree(false);
                    stepSubmissionStepUpdater();
                });
        }
    };

    // cleaning up async tasks
    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);
    return (
        <>
            <div className="case-detail-accordian-signature-con">
                <UncontrolledAccordion defaultOpen={defaultOpenAccord} stayOpen>
                    <AccordionItem>
                        <CustomAccordionHeader
                            containerClassName="case-detail-accordian-container"
                            innerClassName="case-detail-accordian-header"
                            subContainerClassName="case-detail-accordian-header-status"
                            headerCount={processNO}
                            headerText={title}
                            status={accordionStatus.OPEN}
                            targetId={targetId}
                        />

                        <AccordionBody
                            accordionId={targetId}
                            className="case-detail-accordian-body-con"
                        >
                            <Card>
                                <form onSubmit={formik.handleSubmit}>
                                    {/* {userType !== 'ADMIN' ? 
                      ( */}
                                    <Row>
                                        <Col md={12}>
                                            <div className="d-flex">
                                                <div className="case-detail-accordian-body">
                                                    <div>Trademark Publication Form</div>
                                                    <ImageUpload
                                                        image={UploadLogo}
                                                        inputId={3}
                                                        onChange={(e) =>
                                                            handleFileChange(
                                                                e,
                                                                setTrademarkPublicationMedia
                                                            )
                                                        }
                                                        fileName={
                                                            trademarkPublicationMedia?.fileName
                                                        }
                                                        imageError={
                                                            trademarkPublicationMedia?.imageError
                                                        }
                                                        removeImageHandler={() =>
                                                            removeImage(
                                                                setTrademarkPublicationMedia
                                                            )
                                                        }
                                                        errorType={
                                                            errorType === 1
                                                                ? fieldRequirementMessage.UPLOAD_FILE
                                                                : ""
                                                        }
                                                        disabled={isSubmittingStepThree}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="bg-white p-3 pt-4 case-details-accordian-date-field">
                                                <Label>Add Fee for this Step (USD)</Label>
                                                <div className="mb-3">
                                                    <TextInput
                                                        placeholder="Add Fee (USD)"
                                                        name={"stepFees"}
                                                        type="number"
                                                        className="hide-icon"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.stepFees}
                                                        disabled={isSubmittingStepThree}
                                                        autoFocus
                                                        preAppendIconFile={"$"}
                                                    />
                                                    <FormikErrorText
                                                        formikInstance={formik}
                                                        fieldName={"stepFees"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="bg-white p-3 pt-0 case-details-accordian-date-field">
                                                <Label>Fee Deadline</Label>
                                                <div className="mb-3">
                                                    <TextInput
                                                        name={"feeDeadline"}
                                                        type="date"
                                                        className="hide-icon"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.feeDeadline}
                                                        autoFocus
                                                        iconFile={DateIcon}
                                                        min={moment().format("YYYY-MM-DD")}
                                                        disabled={isSubmittingStepThree}
                                                    />
                                                    <FormikErrorText
                                                        formikInstance={formik}
                                                        fieldName={"feeDeadline"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <>
                                        <hr />
                                        <Row>
                                            <div className="accordian-body-footer-btn">
                                                <CustomButton
                                                    title={buttonTitle.SEND}
                                                    className="trademark-body-footer-accept-button shadow-none me-3"
                                                    color="#704DE7"
                                                    type={"submit"}
                                                    loading={isSubmittingStepThree}
                                                    disabled={isSubmittingStepThree}
                                                />
                                            </div>
                                        </Row>
                                    </>
                                </form>
                            </Card>
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            </div>
        </>
    );
}

// handling prop types
Publication.propTypes = {
    openAccord: PropTypes.string,
    toggleButtonHandler: PropTypes.func,
    title: PropTypes.string,
    targetId: PropTypes.string,
    accordId: PropTypes.string,
    isPA: PropTypes.bool,
    processNO: PropTypes.string
};
