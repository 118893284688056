import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Viewer from "react-viewer";
import DocumentViewerModal from "../CaseManagement/DocumentViewerModal";
import { store } from "store";
import moment from "moment";
import { Loader } from "components/Loader";
import TradeMarkLogo from "../../assets/images/aiAPaiT/case-mangement/trademark.png";
import DownloadIcon from '../../assets/images/download.svg';

const MESSAGE_LENGTH = 500;

const Messages = ({ list, loading }) => {
    const [seeMore, setSeeMore] = useState(false);
    const userId = store?.getState()?.userAuth?.user?.id;
    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false)
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [imagePreview, setImagePreview] = useState("");

    const handleImageClick = (message) => {
        if (message && message?.attachments?.length) {
            setImagePreview(message?.attachments[0]?.attachment?.path);
            setIsPdfViewerModalActive(true)
        }
    }; 

     // pdf/image document view modal close handler
     const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("")
        setIsPdfViewerModalActive(false)
    }

    const documentDownloader = (documentUrl) => {
        window.open(documentUrl, "_blank")
    }

    const findMessageOwner = (item) => {
        if(item?.senderId === userId ||
        item?.sender?.type == "SUBADMIN" ||
        item?.sender?.type == "ADMIN" ||
        item?.type == "ADMIN_EVENT" ||
        item?.type == "SUBADMIN_EVENT"){
            return true
        }
        return false
    }

    const handleLongMessage = (item) => {
        if(item?.content?.length >= MESSAGE_LENGTH){
            return(
                (
                    <>
                        {seeMore ? item?.content.slice(0) : null}
                        {!seeMore ? item?.content.slice(0,MESSAGE_LENGTH ) : null}
                        <button
                            className="see-more"
                            onClick={() => {
                                setSeeMore(!seeMore);
                            }}
                        >
                            {!seeMore
                                ? "See more"
                                : "See less"}
                        </button>
                    </>
                ) 
            )
        } else {
            return(
                item?.content
            )
        }
    }
    return (
        <>
            <div>
                {loading ? <Loader />: null}
                {list?.map((msg, idx) => (
                        <div className="msg-width-scroll" key={idx}>
                            <h2 className="msg-daytime-bar">
                                <span>{moment(msg?.date).format("dddd, MMMM Do YYYY")}</span>
                            </h2>
                            {msg?.chat?.map((item, index) => (
                                <div className="d-flex flex-column" key={index}>
                                    <div>
                                        <div
                                            className={`msg-text ${findMessageOwner(item) ? "owner" : ""}`}
                                        >
                                            <div className="pl-3">
                                                <div
                                                    className={`elevation-0 ${ findMessageOwner(item) ? "owner-message" : "recipient-msg-con"}`}
                                                >
                                                    <div className="pa-2 d-flex flex-column message-box response">
                                                        {item?.attachments?.length ? (
                                                            <>
                                                            <div onClick={() => handleImageClick(item)}>                                                            
                                                                {item?.attachments[0]?.attachment?.path.split('.')[item?.attachments[0]?.attachment?.path.split('.').length - 1] !== "pdf" ? (
                                                                    <div
                                                                        className="chat-image-view-container"
                                                                        
                                                                    >
                                                                        <img
                                                                            className="w-100 h-100"
                                                                            src={item?.attachments[0]?.attachment?.path}
                                                                            alt="chat-message"
                                                                        />
                                                                    </div>
                                                                    ) : (
                                                                    <div className="bg-white pdf-img-con">
                                                                        <img src={ TradeMarkLogo} alt="trademark" className="pdf-dummy-img"/>
                                                                        <span>{item?.attachments[0]?.attachment?.name}</span>                                                                        
                                                                    </div>
                                                                    )}
                                                            </div>
                                                            <div
                                                            className="view-file-buttons download-pdf-btn"
                                                            onClick={() => documentDownloader(item?.attachments[0]?.attachment?.path)}
                                                             >{"Download"}</div>
                                                             </>
                                                        ) : null}
                                                        <span className="align-self-start chat-message">
                                                            {handleLongMessage(item)}
                                                        </span>
                                                    </div> 
                                                </div>

                                                <span
                                                    className={`message-time ${
                                                        item?.senderId === userId ||
                                                        item?.sender?.type == "SUBADMIN" ||
                                                        item?.sender?.type == "ADMIN" ||
                                                        item?.type == "ADMIN_EVENT" ||
                                                        item?.type == "SUBADMIN_EVENT"
                                                            ? "msg-time-owner"
                                                            : ""
                                                    }`}
                                                >
                                                    {moment(item?.updatedAt).fromNow()}{" "}
                                                    {item?.type === "ADMIN_EVENT" ? " ● admin" : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                ))}
            </div>
            <DocumentViewerModal
            modalActivator={isPdfViewerModalActive}
            modalCloseHandler={pdfViewerModalCloseHandler}
            modalData={imagePreview}
            isDataFetching={false}
            />
        </>
    );
};

// handling prop types
Messages.propTypes = {
    userData: PropTypes.any,
    list: PropTypes.any,
    loading: PropTypes.bool
};

export default Messages;
