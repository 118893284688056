export const APP_ROUTES = {
    // **** Auth
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    OTP_VERIFY_CODE: "/forget-verify-code",
    RESET_PASSWORD: "/reset-password",
    // REGISTER: "/register",

    CHANGE_PASSWORD: "/change-password",

    //sidebar
    DASHBOARD: "/dashboard",
    CASE_MANAGEMENT: "/case-management",
    CASE_MANAGEMENT_DETAILS: "/case-management/case-details",
    CUSTOMER_MANAGEMENT: "/customer-management",
    CUSTOMER_MANAGEMENT_DETAILS: "/customer-management/customer-details",
    ADMIN: "/sub-admins",
    SUB_ADMINS: "/departments",
    SUB_ADMINS_DETAILS: "/departments/details",
    EARNINGS: "/earnings",
    EARNINGS_DETAILS: "/earnings/details",
    COUNTRIES: "/countries",
    // GOODS_SERVICES: "/goods-services",
    CHATS: "/chats",
    SETTINGS: "/settings",
    SETTINGS_DETAILS: "/settings/edit-profile",
    SETTINGS_CHANGE_PASS: "/settings/change-pass"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys?.forEach((item) => {
            route = route?.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
