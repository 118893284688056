import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { HeaderDataTable } from "../ComponentsProject/HeaderDataTable";
import { CaseStatus, sortingOptions, adminsHeader } from "../../constants";
import { Sorting } from "components/Sorting";
import { AddNewDepartment } from "components/AddNewDepartment";

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { deleteUser, getAllAdmins, updateUser } from "api/api.service";

import editIcon from "../../assets/images/aiAPaiT/Table/edit-icon.svg";

import disableIcon from "../../assets/images/aiAPaiT/Table/disable-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";
import { METATAGS } from "../../constants";
import { AddNewAdmin } from "components/AddNewAdmin";
import toast from "react-hot-toast";
import DeleteAdmin from "./DeleteAdmin";

const USER_TYPE = "sub-admin";
const dataAdd = {
    name: "",
    email: "",
    countries: []
};

const Listing = () => {
    const [addNewAdminModal, setAddNewAdminModal] = useState(false);
    const [addNewItemData, setAddNewItemData] = useState(dataAdd);
    const [editAdmin, setEditAdmin] = useState(false);
    const [isToggleLoading, setIsToggleLoading] = useState(false);
    const [phoneNum, setPhoneNum] = useState("");
    const [userID, setUserID] = useState(undefined);
    const [isDeleteUser, setIsDeleteUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: getAllAdmins,
        dataKey: "admins",
        totalKey: "total",
        params: {},
        deps: []
    });

    const handleStatusButtonClick = async (e, id, status) => {
        e.preventDefault();
        try {
            let currentStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
            setIsToggleLoading(true);
            let res = await updateUser(id, { status: currentStatus });

            toast.success(`Sub Admin status has been updated successfully.`);
            data.map((el) => {
                if (el.id === id) {
                    status && (el.status = currentStatus);
                }
            });
        } finally {
            setIsToggleLoading(false);
        }
    };

    // edit admin modal input change handling
    const vendorEditHandler = (data) => {
        setAddNewItemData({
            ...addNewItemData,
            name: data?.name,
            phoneNum: `${data?.countryCode}${data?.phone}`,
            userId: data?.id
        });
        setEditAdmin(true);
        setAddNewAdminModal(true);
    };

    // add new admin modal activation handling
    const ButtonClickHanlder = (e) => {
        setAddNewAdminModal(true);
    };

    // add new admin modal close handling
    const addNewDeptModalCloseHandler = (e) => {
        setAddNewItemData(dataAdd);
        setAddNewAdminModal(false);
        setTimeout(() => {
            setEditAdmin(false);
        }, 500);
    };

    // add new admin modal change handling
    const modalInputChangeHandler = (e) => {
        setAddNewItemData({ ...addNewItemData, [e.target.name]: e.target.value });
    };

    // add new admin country dropdown on change handling
    const selectCountryHandler = (e) => {
        setAddNewItemData({ ...addNewItemData, countries: e?.value });
    };

    //deleteHandler
    const deleteClickHandler = (Id) => {
        setUserID(Id);
        setIsDeleteUser(true);
    };

    const handleToggleModal = () => setIsDeleteUser(!isDeleteUser);

    const handleDelete = async () => {
        // Handle delete action
        setIsDeletingUser(true);

        try {
            let res = await deleteUser(userID);
            toast.success(`Sub Admin has been deleted successfully.`);
            handleToggleModal();
            request();
        } finally {
            setIsDeletingUser(false);
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.SUB_ADMINS}</title>
            </MetaTags>
            <Row className="p-3 case-management-con">
                <Col md={12}>
                    <HeaderDataTable
                        buttonTitle="Add New Sub Admin"
                        searchPlaceholder="Search by sub admin name"
                        searchValue={searchText}
                        onSearchChange={handleSearchChange}
                        onButtonClick={ButtonClickHanlder}
                    />
                </Col>
                <Col md={12}>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: adminsHeader.ADMIN_NAME,
                                className: "bold-font sa-cust-name text-truncate ",
                                render: (data) => data?.name || "-"
                            },
                            {
                                title: adminsHeader.EMAIL,
                                className: "sa-subadmin-name",
                                render: (data) => data?.email || "-"
                            },
                            {
                                title: adminsHeader.PHONE_NUMBER,
                                className: "sa-region",
                                render: (data) => data?.countryCode + data?.phone || "-"
                            },
                            {
                                title: adminsHeader.STATUS,
                                className: "status-toggle-con",
                                render: (data) => {
                                    return (
                                        <div>
                                            <label
                                                className={`switch-dt ${
                                                    isToggleLoading ? "switch-pointer-events" : ""
                                                }`}
                                                onClick={(e) =>
                                                    handleStatusButtonClick(
                                                        e,
                                                        data?.id,
                                                        data?.status
                                                    )
                                                }
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={data?.status === "ACTIVE"}
                                                />
                                                <span className="slider-dt round-dt"></span>
                                            </label>
                                            <span
                                                className={`ms-3 toggle-btn-typo-dt ${
                                                    data?.status === "ACTIVE"
                                                        ? "color-active-dt"
                                                        : ""
                                                }`}
                                            >
                                                {data?.status === "ACTIVE" ? "Active" : "Inactive"}
                                            </span>
                                        </div>
                                    );
                                }
                            },

                            {
                                title: adminsHeader.ACTION,
                                className: "sa-action",
                                render: (data) => (
                                    <div className="d-flex  align-items-center">
                                        <span
                                            className=" pointer ms-3 me-2"
                                            onClick={() => vendorEditHandler(data)}
                                        >
                                            <img src={editIcon} alt="details icon" />
                                        </span>
                                        <span onClick={() => deleteClickHandler(data?.id)}>
                                            <i
                                                className="bx bx-trash pointer"
                                                style={{
                                                    color: "red",
                                                    fontSize: "1.2rem",
                                                    height: "100%",
                                                    marginTop: "6px"
                                                }}
                                            ></i>
                                        </span>
                                    </div>
                                )
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            className="pagination-contain "
                            rowLimit={pageSize}
                        />
                    </div>
                </Col>
            </Row>
            <AddNewAdmin
                modalActivator={addNewAdminModal}
                modalCloseHandler={addNewDeptModalCloseHandler}
                modalData={addNewItemData}
                dataChangeHandler={modalInputChangeHandler}
                selectCountryHandler={selectCountryHandler}
                isEdit={editAdmin}
                phoneNum={phoneNum?.phone}
                refetchData={request}
            />
            <DeleteAdmin
                isOpen={isDeleteUser}
                toggle={handleToggleModal}
                onDelete={handleDelete}
                yesLoading={isDeletingUser}
            />
        </>
    );
};

export default Listing;
