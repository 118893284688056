import PropTypes from "prop-types";
import React from "react";
import TradeMarkLogo from "../../assets/images/aiAPaiT/case-mangement/trademark.png";

import "./CaseManagement.scss";

const TrademarkDetail = ({ 

  documentViewerHandler = () => {}, 
  documentData }) => {

  const documentViewer = (documentUrl) => {
      documentViewerHandler(documentUrl)
  }

  const documentDownloader = (documentUrl) => {
      window.open(documentUrl, "_blank")
  }

  return (
      <div className="d-flex">
          <div className="case-detail-accordian-body">
              {documentData?.path.split('.')[documentData?.path.split('.').length - 1] !== "pdf" ? (

                  <div className="case-detail-accordian-body-img thumbnail-img-con">
                      <img src={documentData?.path} alt="trademark" />
                  </div>
              ) : (
                  <div className="case-detail-accordian-body-img">
                      <img src={ TradeMarkLogo} alt="trademark" />
                  </div>
              )}
              
          </div>

          <div className="case-detail-trademark-con">
              <div className="case-detail-accordian-body-trademark-body">
                  <p>{documentData?.name || "-"}</p>
                  <div className="trademark-body-button-container justify-content-start">
                      <div
                          className="view-file-buttons me-3"
                          onClick={() => documentViewer(documentData?.path)}
                      >{"View"}</div>
                      <div
                          className="view-file-buttons"
                          onClick={() => documentDownloader(documentData?.path)}
                      >{"Download"}</div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default TrademarkDetail

TrademarkDetail.propTypes = {
  documentViewer: PropTypes.any,
  documentDownloader: PropTypes.any
};