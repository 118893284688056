import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";

import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { HeaderDataTable } from "../ComponentsProject/HeaderDataTable";
import { earningsHeader, sortingOptions } from "../../constants";
import { Sorting } from "components/Sorting";

import detailsIcon from "../../assets/images/aiAPaiT/Table/view-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";
import { METATAGS, stepsName, UserRoleType } from "../../constants";
import { getAllEarnings, GetPdfReport } from "api/api.service";
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { downloadCSVData} from "utils/downloadUtils";
import { zoneFormatToDDMMYY } from 'utils/dateFormatUtils'

const Listing = () => {
    const [isCSVDataLoading, setIsCSVDataLoading] = useState(false);
    const userType = useSelector(store => store?.userAuth?.user?.type)

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: getAllEarnings,
        params: {},
        deps: []
    });

    // download csv handling
    const ButtonClickHanlder = async (e) => {
        try {
            setIsCSVDataLoading(true);
            let res = await GetPdfReport();
            res?.blob().then((blob) => downloadCSVData(blob));
        } finally {
            setIsCSVDataLoading(false);
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.EARNINGS}</title>
            </MetaTags>
            <Row className="p-3 case-management-con">
                <Col md={12}>
                    <HeaderDataTable
                        searchPlaceholder="Search here"
                        searchValue={searchText}
                        onSearchChange={handleSearchChange}
                        buttonTitle="Download CSV"
                        onButtonClick={ButtonClickHanlder}
                    />
                </Col>
                <Col md={12}>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: earningsHeader.CASE_ID,
                                className: "cm-cust-name text-truncate ",
                                render: (data) => data?.id || "-"
                            },
                            {
                                title: earningsHeader.CASE_NAME,
                                className:
                                    "bold-font cm-cust-name width-wrap-text case-name-col-dpts",
                                render: (data) => data?.caseTitle || "-"
                            },
                            {
                                title: earningsHeader.CUSTOMER_NAME,
                                className: "bold-font cm-cust-name text-truncate ",
                                render: (data) => data?.customerName || "-"
                            },
                            {
                                title: earningsHeader.PROCESS,
                                className: "bold-font cm-cust-name text-truncate ",
                                render: (data) =>
                                    stepsName.getDisplayTextKey(data?.trademarkStep) || "-"
                            },
                            ...( userType !== UserRoleType.SubAdmin ? [{
                                title: earningsHeader.ADMIN_NAME,
                                className: "bold-font cm-subadmin-name",
                                render: (data) => data?.adminName || "-"
                            }]: []),
                            {
                                title: earningsHeader.CREATED_AT,
                                className: "bold-font cm-subadmin-name",
                                render: (data) =>
                                zoneFormatToDDMMYY(data?.createdAt) || "-"
                            },
                            {
                                title: earningsHeader.STEP_FEE,
                                className: "bold-font cm-region",
                                render: (data) => <>${data?.earnings}</> || "-"
                            },
                            {
                                title: earningsHeader.ACTION,
                                className: "cm-action",
                                render: (data) => (
                                    <>
                                        <Link
                                            to={`${APP_ROUTES.EARNINGS_DETAILS}/${data?.id}`}
                                            className=" pointer "
                                        >
                                            <img src={detailsIcon} alt="details icon" />
                                        </Link>
                                    </>
                                )
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            currentPage={page}
                            totalData={total}
                            rowLimit={pageSize}
                            onPageClick={handlePageClick}
                            className="pagination-contain "
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Listing;
