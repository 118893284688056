import { injectBaseConstantMethods } from "./BaseConstants";

export const RESPONSE_STATUS = {
    Done: "Done",
    Rejected: "Rejected",
    Pending: "PendingAdmin",
    Awaiting: "PendingCustomer",
    Completed: "Completed",
    Dispatched: "Dispatched"
};

const displayTextKeys = {
    [RESPONSE_STATUS.Done]: "Done",
    [RESPONSE_STATUS.Rejected]: "Rejected",
    [RESPONSE_STATUS.Pending]: "Pending",
    [RESPONSE_STATUS.Awaiting]: "Awaiting",
    [RESPONSE_STATUS.Completed]: "Completed",
    [RESPONSE_STATUS.Dispatched]: "Dispatched"
};

const labelClass = {
    [RESPONSE_STATUS.Done]: "green-bg-text",
    [RESPONSE_STATUS.Completed]: "green-bg-text",
    [RESPONSE_STATUS.Dispatched]: "green-bg-text",
    [RESPONSE_STATUS.Rejected]: "red-bg-text",
    [RESPONSE_STATUS.Pending]: "blue-bg-text",
    [RESPONSE_STATUS.Awaiting]: "yellow-bg-text"
};

export default injectBaseConstantMethods(RESPONSE_STATUS, displayTextKeys, labelClass);
