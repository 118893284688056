import React from "react";

import deleteModalImg from "../../assets/images/aiAPaiT/Admin/delete-modal-icon.svg";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";
import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";

const DeleteAdmin = ({ isOpen, toggle, onDelete, yesLoading }) => {
    const modalConfig = {
        Title: "Are you sure you want to delete this Sub Admin?",

        YesButtonText: "Delete",
        NoButtonText: "Cancel",
        ImageSrc: deleteModalImg
    };

    return (
        <CustomModal
            className="add-department-custom-modal"
            isOpen={isOpen}
            toggle={toggle}
            size="md"
            data={
                <>
                    <div className="add-department-modal d-flex  align-items-center px-5  ">
                        <img src={backIcon} alt="close modal icon" onClick={toggle} />
                        <p className="my-2 mx-auto">{"Delete Sub Admin"}</p>
                    </div>
                    <div className="text-center mt-3 mb-5 logout-modal-con">
                        <img src={modalConfig.ImageSrc} alt="Delete Confirmation" className="" />

                        {modalConfig.Title && (
                            <p className=" my-4 desc-typo">{modalConfig.Title}</p>
                        )}

                        <CustomButton
                            title={modalConfig.YesButtonText}
                            loading={yesLoading}
                            color="primary"
                            className="bv-btn-sm shadow-theme fw-bold"
                            onClick={onDelete}
                        />
                    </div>
                </>
            }
        />
    );
};

export default DeleteAdmin;
