import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../constants";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES, getRoute } from "helpers/routeHelpers";
import { ResetPassword } from "api/api.service";
import { SuccessModalCustom } from "components/SuccessModalCustom";
import loginImage from "assets/images/aiAPaiT/login/login-img.png";
import passIcon from "assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "assets/images/aiAPaiT/show-lock-icon.svg";

const ResetPasswordView = (props) => {
    let {} = props;

    const history = useHistory();
    const { state } = useLocation();
    const [resetSuccessModal, setResetSuccessModal] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState(false);
    const [resetPasswordFieldType, setResetPasswordFieldType] = React.useState(false);
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);

    const modalCloseHandler = () => {
        setResetSuccessModal(false);
        history.push(APP_ROUTES.LOGIN);
    };

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const resetPasswordFieldChangeHandler = () => {
        setResetPasswordFieldType(!resetPasswordFieldType)
      }
    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            .required("Please Enter Your New Password")
            .min(6, "Password should be minimum 6 characters long")
            .max(25, "Password should be between 6 to 25 characters")
            .test(
              "regex",
              "Password must contain characters, special characters, and numbers",
              (val) => {
                  let regExp = new RegExp(
                      "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
                  );
      
                  return regExp.test(val);
              }
            ),
            confirmPassword: Yup.string()
            .oneOf(
                [Yup.ref("newPassword")],
                "Please make sure your passwords match"
                )
            .required("Please Enter Your Confirm Password")
        }), 

        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        let { confirmPassword } = values;
        try {
            setIsLoading(true);
            let body = {
                token: state?.tokenId,
                password: confirmPassword
            };
            let res = await ResetPassword(body);
            toast.success("Password has been reset successfully !");
            history.push(getRoute(APP_ROUTES?.LOGIN));
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <MetaTags>
                <title>{METATAGS.RESET_PASSWORD}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div className="text-center">
                        <h1 className="text-white fw-normal">
                            <span>PASSWORD RESET</span>
                        </h1>
                    </div>
                }
                data={
                    <div className="bv-forget-wrap margin-top-vh">
                        <p className="login-type ms-0">Reset Password</p>
                        <form className="mt-3" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <TextInput
                                    placeholder="Enter New Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    name="newPassword"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.newPassword}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword ? (
                                    <span className="error">{formik.errors.newPassword}</span>
                                ) : null}
                            </div>
                            <div className="mb-5">
                                <TextInput
                                    placeholder="Re-enter New Password"
                                    type={!resetPasswordFieldType ? "password" : "text"}
                                    name="confirmPassword"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    iconFile={!resetPasswordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={resetPasswordFieldChangeHandler}
                                />
                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <span className="error">{formik.errors.confirmPassword}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                color="primary"
                                title="Reset Password"
                                className="w-100 ms-0"
                            />
                        </form>
                    </div>
                }
                leftImgUrl={loginImage}
            />
            <SuccessModalCustom
                btnTitle="Go to Login"
                mainContent="Password han been reset successfully "
                modalActivator={resetSuccessModal}
                modalCloseHandler={modalCloseHandler}
            />
        </>
    );
};

export default withRouter(ResetPasswordView);
