import React from "react";
import { Switch, BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";

import "./assets/scss/theme/theme1/theme.scss";

import { APP_ROUTES } from "helpers/routeHelpers";
import { withTitle } from "hoc";

import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import ForgetPassword from "pages/Authentication/ForgetPassword";
import ForgetVerifyCode from "pages/Authentication/ForgetVerifyCode";
import ResetPassword from "pages/Authentication/ResetPassword";

import { PageTitles } from "./constants";
import { DefaultLayout } from "./containers";

const App = () => {
    
    const layout = useSelector((state) => state.Layout);

    function getLayout() {
        let layoutCls = VerticalLayout;
        switch (layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    const Layout = getLayout();

    const LoginWithTitle = withTitle({
        component: Login,
        title: PageTitles.LOGIN
    });
    const ForgetPasswordWithTitle = withTitle({
        component: ForgetPassword,
        title: PageTitles.FORGET_PASSWORD
    });
    const LogoutWithTitle = withTitle({
        component: Logout,
        title: PageTitles.LOGOUT
    });
    const ResetPasswordWithTitle = withTitle({
        component: ResetPassword,
        title: PageTitles.RESET_PASSWORD
    });
    const OTPVerifyCodeWithTitle = withTitle({
        component: ForgetVerifyCode,
        title: PageTitles.OTP_VERIFY_CODE
    });

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    <Route
                        exact
                        path={APP_ROUTES.LOGOUT}
                        component={LogoutWithTitle}
                        name={PageTitles.LOGOUT}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.LOGIN}
                        component={LoginWithTitle}
                        name={PageTitles.LOGIN}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.FORGET_PASSWORD}
                        component={ForgetPasswordWithTitle}
                        name={PageTitles.FORGET_PASSWORD}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.OTP_VERIFY_CODE}
                        component={OTPVerifyCodeWithTitle}
                        name={PageTitles.OTP_VERIFY_CODE}
                    />
                    <Route
                        exact
                        path={APP_ROUTES.RESET_PASSWORD}
                        component={ResetPasswordWithTitle}
                        name={PageTitles.RESET_PASSWORD}
                    />
                    <Route
                        path="/"
                        render={(props) => <DefaultLayout {...props} Layout={Layout} />}
                    />
                    <Redirect to={APP_ROUTES.LOGIN} />
                </Switch>
            </Router>

            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    className: "",
                    duration: 3000,
                    success: {
                        duration: 3000
                    }
                }}
            />
        </React.Fragment>
    );
};

export default App;
