export function findOutAccordianId(step, stepStatus, updateTarget) {
    if (step === "FILING" && stepStatus === "Rejected") {
        updateTarget(["21"]);
    } else if (step === "FILING") {
        updateTarget(["11"]);
    } else if (step === "POAAPPLICATION" && stepStatus === "Awaiting") {
        updateTarget(["23"]);
    } else if (step === "POAAPPLICATION" && stepStatus === "Pending") {
        updateTarget(["24"]);
    } else if (step === "POAAPPLICATION" && stepStatus === "Rejected") {
        updateTarget(["26"]);
    } else if (step === "PUBLICATION" && stepStatus === "Rejected") {
        updateTarget(["36"]);
    } else if (step === "PUBLICATION" && stepStatus === "Awaiting") {
        updateTarget(["33"]);
    } else if (step === "PUBLICATION" && stepStatus === "Pending") {
        updateTarget(["34"]);
    } else if (step === "REGISTRATION" && stepStatus === "Rejected") {
        updateTarget(["47"]);
    } else if (step === "REGISTRATION" && stepStatus === "Awaiting") {
        updateTarget(["44"]);
    } else if (step === "REGISTRATION" && stepStatus === "Pending") {
        updateTarget(["45"]);
    } else if (step === "TMCRDOWNLOAD" && stepStatus === "Rejected") {
        updateTarget(["58"]);
    } else if (step === "TMCRDOWNLOAD" && stepStatus === "Awaiting") {
        updateTarget(["55", "06"]);
    } else if (step === "TMCRDOWNLOAD" && stepStatus === "Pending") {
        updateTarget(["05"]);
    } else {
        updateTarget(["05"]);
    }
}
