import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Col, Row, Label, Card } from "reactstrap";
import MetaTags from "react-meta-tags";

import { DepartmentHeader, DepartmentHeaderButtons } from "components/DepartmentHeader";
import { DataTable } from "components/DataTable";
import { Pagination } from "components/Pagination";

import {
    CaseStatus,
    ResponseStatus,
    sortingOptions,
    caseManagmentHeader,
    customStyles,
    caseStatusOptions,
    filterOptions,
    responseStatusOptions,
    processStatusOptions,
    countriesOptions,
    METATAGS,
    stepsName
} from "../../constants";
import { Sorting } from "components/Sorting";
import { SearchBox } from "components/SearchBox";
import { AutoComplete } from "components/AutoComplete";
import { AddNewDepartment, CountriesListModal } from "components/AddNewDepartment";

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { GetDepartmentById, updateUser } from "api/api.service";

import detailsIcon from "../../assets/images/aiAPaiT/Table/view-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";

const Details = () => {
    // api call count
    let APIHitCount = 0;
    const { id } = useParams();
    const [addNewDepartmentModal, setAddNewDeparmentModal] = useState(false);
    const [addNewItemData, setAddNewItemData] = useState({});
    const [editDepartment, setEditDepartment] = useState(true);
    const [countriesListModal, setCountriesListModal] = useState(false);
    const [isSuspendUserLoading, setIsSuspendUserLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [filters, setFilters] = useState({
        filterType: "",
        statusValue: []
    });

     // if department then filtering out the assigned(active) countries only
     const activeCountries = userData?.countries?.map(el => {
        if(el?.status == "ACTIVE"){
            return {
                label: el?.code ? `${el?.name}(${el?.code})` : `${el?.name}`,
                value: el?.name
            } }
        } ).filter(el => el !== undefined)
    
    // finding filter options accroding user selection
    const sortingOptionsEvaluated =
        filters.filterType === "countryName"
            ? activeCountries
            : filters.filterType === "trademarkStatus"
            ? caseStatusOptions
            : filters.filterType === "trademarkStep"
            ? processStatusOptions
            : responseStatusOptions;

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        updatePageOnFilterChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: GetDepartmentById,
        params: {
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumns: filters?.filterType }),
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumnsValue: filters?.statusValue?.value })
        },
        deps: [filters.statusValue],
        dataId: id
    });

    // type fitler on change handling
    const handleFilterTypeSelect = (val, valueType) => {
        if (val) {
            if(valueType){
                setFilters({ ...filters, filterType: val?.value, statusValue: "" })
            }else{
                updatePageOnFilterChange()
                setFilters({ ...filters, statusValue: val });
            }
        }
    };

    // add new department modal close handling
    const addNewDeptModalCloseHandler = (e) => {
        setAddNewDeparmentModal(false);
    };

    // country change handling
    const selectCountryHandler = (e) => {
        setAddNewItemData({ ...addNewItemData, countries: e?.value });
    };

    // add new department modal input change handling
    const modalInputChangeHandler = (e) => {
        setAddNewItemData({ ...addNewItemData, [e.target.name]: e.target.value });
    };

    // view country list modal close handling
    const CoutriesListModalCloseHandler = () => {
        setCountriesListModal(false);
    };

    // edit department modal input change handling
    const vendorEditHandler = () => {
        let contriesDataModified = data?.countries?.map((el) => ({
            label: el?.name,
            value: el?.id
        }));
        setAddNewItemData({
            ...addNewItemData,
            name: userData?.name,
            email: userData?.email,
            userId: userData?.id,
            countries: contriesDataModified
        });
        setEditDepartment(true);
        setAddNewDeparmentModal(true);
    };

    // suspend department handling
    const handleSuspendUser = async () => {
        try {
            setIsSuspendUserLoading(true);
            let status = data?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
            await updateUser(id, { status });
            toast.success(
                `Department has been ${
                    status === "ACTIVE" ? "unsuspended" : "suspended"
                } successfully`
            );
            request();
        } finally {
            setIsSuspendUserLoading(false);
        }
    };

    // updating user static data for only first time
    useEffect(() => {
        if (
            data &&
            Object.keys(data).length > 0 &&
            APIHitCount === 0 &&
            Object.keys(userData).length === 0
        ) {
            setUserData(data);
            APIHitCount = 1;
        }
    }, [data]);

    return (
        <>
            <MetaTags>
                <title>{METATAGS.DEPARTMENT_DETAILS}</title>
            </MetaTags>
            <div className="p-3">
                <Card className="dept-header-con mb-3">
                    <DepartmentHeader data={userData} />
                    <DepartmentHeaderButtons
                        viewbtnHandler={() => setCountriesListModal(true)}
                        editBtnHandler={() => vendorEditHandler()}
                        suspendBtnHandler={handleSuspendUser}
                        isUserSuspended={data?.status}
                        isSuspensionLoading={isSuspendUserLoading}
                    />
                </Card>
                <Row className="p-0 case-management-con">
                    <Col md={12}>
                        <div className="bg-white d-flex  align-items-center p-3 listing-header-con">
                            <SearchBox
                                iconClass="main-header-search-icon"
                                className="main-header-search-box main-header-search-box-modified"
                                placeholder="Search here"
                                onChange={handleSearchChange}
                                value={searchText}
                            />
                            <div className="d-flex  main-header-filter-box align-items-center main-header-type-filter-modified">
                                <Label className="ms-4 me-3 mb-0">Filter Type</Label>
                                <div className="header-filter">
                                    <AutoComplete
                                        customStyles={customStyles.WarehouseIM}
                                        isSearchable={false}
                                        onChange={(val) => handleFilterTypeSelect(val, "valueType")}
                                        classNamePrefix="status-header-search-admin"
                                        options={filterOptions}
                                        placeholder="Select Filter Type"
                                    />
                                </div>
                            </div>
                            {filters?.filterType !== "" && (
                                <div className="d-flex  main-header-filter-box  align-items-center main-header-con-modified">
                                    <Label className="ms-4 me-3 mb-0 label-filterBy">
                                        Filter by
                                    </Label>
                                    <div className="header-filter">
                                        <AutoComplete
                                            customStyles={customStyles.WarehouseIM}
                                            isSearchable={
                                                filters.filterType === "countryName" ? true : false
                                            }
                                            onChange={(val) => handleFilterTypeSelect(val)}
                                            value={filters.statusValue}
                                            classNamePrefix="status-header-search-admin"
                                            options={sortingOptionsEvaluated}
                                            placeholder="Select From List"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col md={12}>
                        <DataTable
                            data={data?.departmentDetails}
                            loading={isFetching}
                            config={[
                                {
                                    title: caseManagmentHeader.CASE_ID,
                                    className: "cm-case-id",
                                    render: (data) => data?.caseId || "-"
                                },
                                {
                                    title: caseManagmentHeader.CASE_LISTING,
                                    className:
                                        "bold-font cm-cust-name text-truncate width-wrap-text case-name-col-dpts",
                                    render: (data) => data?.caseTitle || "-"
                                },
                                {
                                    title: caseManagmentHeader.REGION,
                                    className: " cm-region bold-font",
                                    render: (data) => data?.countryName || "-"
                                },
                                {
                                    title: caseManagmentHeader.CUSTOMER_NAME,
                                    className: "bold-font cm-cust-name text-truncate ",
                                    render: (data) => data?.customerName || "-"
                                },
                                {
                                    title: caseManagmentHeader.CUSTOMER_PHONE_NUMBER,
                                    className: "cm-subadmin-name",
                                    render: (data) =>
                                        `${data?.countryCode}${data?.phoneNumber}` || "-"
                                },
                                {
                                    title: caseManagmentHeader.PROCESS,
                                    className: "bold-font cm-process ",
                                    render: (data) =>
                                        stepsName.getDisplayTextKey(data?.trademarkStep) || "-"
                                },
                                {
                                    title: caseManagmentHeader.CASE_STATUS,
                                    className: "cm-status",
                                    render: (data) => {
                                        return (
                                            <div
                                                className={`${CaseStatus.getLabelClass(
                                                    data?.trademarkStatus
                                                )} status-rest `}
                                            >
                                                <p>
                                                    {CaseStatus.getDisplayTextKey(
                                                        data?.trademarkStatus
                                                    ) || "-"}
                                                </p>
                                            </div>
                                        );
                                    }
                                },
                                {
                                    title: caseManagmentHeader.RESPONSE_STATUS,
                                    className: "cm-response-status",
                                    render: (data) => {
                                        return (
                                            <div
                                                className={`${ResponseStatus.getLabelClass(
                                                    data?.trademarkStepsStatus
                                                )} status-rest`}
                                            >
                                                <p>
                                                    {ResponseStatus.getDisplayTextKey(
                                                        data?.trademarkStepsStatus
                                                    ) || "-"}
                                                </p>
                                            </div>
                                        );
                                    }
                                },
                                {
                                    title: caseManagmentHeader.TOTAL_EARNINGS,
                                    className: "bold-font  cm-subadmin-name",
                                    render: (data) => `$${data?.fees}` || "-"
                                },
                                {
                                    title: caseManagmentHeader.ACTION,
                                    className: "cm-action",
                                    render: (data) => (
                                        <Link
                                            to={`${APP_ROUTES.CASE_MANAGEMENT_DETAILS}/${data?.id}`}
                                            className=" pointer "
                                        >
                                            <img src={detailsIcon} alt="details icon" />
                                        </Link>
                                    )
                                }
                            ]}
                        />
                    </Col>
                    <Col md={12}>
                        <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                            <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                            <Pagination
                                currentPage={page}
                                totalData={total}
                                onPageClick={handlePageClick}
                                rowLimit={pageSize}
                                className="pagination-contain "
                            />
                        </div>
                    </Col>
                </Row>
                <AddNewDepartment
                    modalActivator={addNewDepartmentModal}
                    modalCloseHandler={addNewDeptModalCloseHandler}
                    modalData={addNewItemData}
                    dataChangeHandler={modalInputChangeHandler}
                    selectCountryHandler={selectCountryHandler}
                    isEdit={editDepartment}
                    refetchData={request}
                />
                <CountriesListModal
                    modalActivator={countriesListModal}
                    modalCloseHandler={CoutriesListModalCloseHandler}
                    data={data}
                />
            </div>
        </>
    );
};

export default Details;
