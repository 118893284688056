import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { HeaderDataTable } from "../ComponentsProject/HeaderDataTable";
import { CaseStatus, sortingOptions, subAdminsHeader } from "../../constants";
import { Sorting } from "components/Sorting";
import { AddNewDepartment } from "components/AddNewDepartment";

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { getAllDepartments } from "api/api.service";

import detailsIcon from "../../assets/images/aiAPaiT/Table/view-icon.svg";
import editIcon from "../../assets/images/aiAPaiT/Table/edit-icon.svg";
import disableIcon from "../../assets/images/aiAPaiT/Table/disable-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";
import { METATAGS } from "../../constants";

const USER_TYPE = "sub-admin";
const dataAdd = {
    name: "",
    email: "",
    countries: []
};

const Listing = () => {
    const [addNewDepartmentModal, setAddNewDeparmentModal] = useState(false);
    const [addNewItemData, setAddNewItemData] = useState(dataAdd);
    const [editDepartment, setEditDepartment] = useState(false);
    const [phoneNum, setPhoneNum] = useState("");

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: getAllDepartments,
        params: {},
        deps: []
    });

    // edit department modal input change handling
    const vendorEditHandler = (data) => {
        let contriesDataModified = data?.countries?.map((el) => {
            if (el.status === "INACTIVE") {
                return { label: el?.name, value: el?.id, isFixed: true };
            } else {
                return { label: el?.name, value: el?.id };
            }
        });
        setAddNewItemData({
            ...addNewItemData,
            name: data?.name,
            email: data?.email,
            countries: contriesDataModified,
            userId: data?.id
        });
        setEditDepartment(true);
        setAddNewDeparmentModal(true);
    };

    // add new department modal activation handling
    const ButtonClickHanlder = (e) => {
        setAddNewDeparmentModal(true);
    };

    // add new department modal close handling
    const addNewDeptModalCloseHandler = (e) => {
        setAddNewItemData(dataAdd);
        setAddNewDeparmentModal(false);
        setTimeout(() => {
            setEditDepartment(false);
        }, 500);
    };

    // add new department modal change handling
    const modalInputChangeHandler = (e) => {
        setAddNewItemData({ ...addNewItemData, [e.target.name]: e.target.value });
    };

    // add new department country dropdown on change handling
    const selectCountryHandler = (e) => {
        setAddNewItemData({ ...addNewItemData, countries: e?.value });
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.DEPARTMENTS}</title>
            </MetaTags>
            <Row className="p-3 case-management-con">
                <Col md={12}>
                    <HeaderDataTable
                        buttonTitle="Add New Department"
                        searchPlaceholder="Search by department name"
                        searchValue={searchText}
                        onSearchChange={handleSearchChange}
                        onButtonClick={ButtonClickHanlder}
                    />
                </Col>
                <Col md={12}>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: subAdminsHeader.SUBADMIN_NAME,
                                className: "bold-font sa-cust-name text-truncate ",
                                render: (data) => data?.name || "-"
                            },
                            {
                                title: subAdminsHeader.EMAIL,
                                className: "sa-subadmin-name",
                                render: (data) => data?.email || "-"
                            },
                            {
                                title: subAdminsHeader.PHONE_NUMBER,
                                className: "sa-region",
                                render: (data) => data?.countryCode + data?.phone || "-"
                            },
                            {
                                title: subAdminsHeader.CASE_LOAD,
                                className: "bold-font sa-process ",
                                render: (data) =>
                                    (data && data.totalcases !== null && data.totalcases) || "-"
                            },
                            {
                                title: subAdminsHeader.TOTAL_EARNINGS,
                                className: "cm-region bold-font ",
                                render: (data) => `$${data?.earnings}` || "-"
                            },
                            {
                                title: subAdminsHeader.ACTION,
                                className: "sa-action",
                                render: (data) => (
                                    <>
                                        <Link
                                            to={`${APP_ROUTES.SUB_ADMINS_DETAILS}/${data?.id}`}
                                            className=" pointer "
                                        >
                                            <img src={detailsIcon} alt="details icon" />
                                        </Link>
                                        <span
                                            className=" pointer ms-3"
                                            onClick={() => vendorEditHandler(data)}
                                        >
                                            <img src={editIcon} alt="details icon" />
                                        </span>
                                    </>
                                )
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            className="pagination-contain "
                            rowLimit={pageSize}
                        />
                    </div>
                </Col>
            </Row>
            <AddNewDepartment
                modalActivator={addNewDepartmentModal}
                modalCloseHandler={addNewDeptModalCloseHandler}
                modalData={addNewItemData}
                dataChangeHandler={modalInputChangeHandler}
                selectCountryHandler={selectCountryHandler}
                isEdit={editDepartment}
                phoneNum={phoneNum?.phone}
                refetchData={request}
            />
        </>
    );
};

export default Listing;
