import React, { useState, useRef } from "react";
import { Tooltip } from "reactstrap";
import uploadIcon from "../../assets/images/aiAPaiT/case-mangement/upload-logo.png";

const ImageUpload = ({
    onChange,
    fileName,
    imageError,
    removeImageHandler,
    inputId,
    errorType,
    disabled
}) => {
    const [tooltipOpen, setToolTipOpen] = useState(false);
    const ref = useRef();
    const toggle = () => {
        setToolTipOpen(!tooltipOpen);
    };
    const handleImageRemove = () => {
        if (disabled) return;
        ref.current.value = "";

        removeImageHandler();
    };
    return (
        <>
            <div>
                <div></div>
                <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    name="file"
                    id={`my-file-${inputId}`}
                    style={{ display: "none" }}
                    ref={ref}
                    onChange={onChange}
                    disabled={disabled}
                />
                <label
                    htmlFor={`my-file-${inputId}`}
                    className="place-order-image-con d-flex justify-content-center align-items-center case-detail-accordian-body-img size-modifier"
                >
                    Upload File
                    <img src={uploadIcon} alt="upload icon" className="mt-2" />
                </label>
            </div>

            {imageError && (
                <span className="d-block text-danger file-upload-error">{imageError}</span>
            )}
            {fileName && (
                <div className="d-flex justify-content-between align-items-center">
                    <span className="d-block img-upload-name text-truncate" id="TooltipExample">
                        {fileName}
                    </span>
                    <Tooltip
                        placement="right"
                        target="TooltipExample"
                        isOpen={tooltipOpen}
                        toggle={toggle}
                    >
                        {fileName}
                    </Tooltip>

                    <div className="div-container">
                        <div
                            className="custom-btn-close-popup "
                            onClick={handleImageRemove}
                            type="button"
                        >
                            <i
                                className={`mdi mdi-close-circle font-size-24 clr-theme-secondary delete-icon`}
                                role="button"
                            ></i>
                        </div>
                    </div>
                </div>
            )}
            {errorType ? (
                <>
                    <span className="text-danger">{errorType}</span>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ImageUpload;
