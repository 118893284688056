import React, { useState } from "react";
import moment from 'moment'
import * as Yup from "yup";
import { useFormik } from "formik";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  AccordionBody,
  Label,
  AccordionItem,
  UncontrolledAccordion
} from "reactstrap";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { CustomAccordionHeader } from "./Accordion";
import TrademarkDetail from './TrademarkDetail';
import DocumentViewerModal from "./DocumentViewerModal";
import { CaseRejection } from "components/CaseRejectionModal";
import { APP_ROUTES } from "helpers/routeHelpers";

import DateIcon from '../../assets/images/aiAPaiT/case-mangement/date-icon.svg';
import FormikErrorText from "components/FormikErrorText/FormikErrorText";
import { accordionStatus, buttonTitle, fieldRequirementMessage } from "./Constants";
 
export default function FilingCompleted ({
    defaultOpenAccord=[],
    title="",
    data_description,
    nextStepHandler= () => {},
    processNO, 
    isReject=false,
    targetId="",
    isCompleted,
    isFormTwoVisible=false,
    nextStepTargetId="",
    refetchStepsAPIHandler=() => {},
    rejectionReason= "",
    isSubmittingStep=false}) {

    const history = useHistory()
    let dataValue = isCompleted ? data_description?.expiryDate : "";

    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false)
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);

    // rejection modal close handling
    const rejectionModalCloseHandler = (isReject) => {
        isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
        setRejectionSuccessModal(false);
    };

    // formik validation for input fields
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            feeDeadline: dataValue
        },
        validationSchema: Yup.object({
            feeDeadline: Yup.date().required(fieldRequirementMessage.DATE_FIELD)
        }),
        onSubmit: (values) => {
            nextStepHandler(nextStepTargetId, values)
        }
    }); 

    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl)
        setIsPdfViewerModalActive(true)
    };

    // pdf/image document view modal close handling
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("")
        setIsPdfViewerModalActive(false)
    }
  return(
    <>
    <div className={`case-detail-accordian-signature-con ${isReject && "accordion-disable-class"}`}>
      <UncontrolledAccordion  
        defaultOpen={defaultOpenAccord}
        stayOpen>
          <AccordionItem>
              <CustomAccordionHeader
                  containerClassName="case-detail-accordian-container"
                  innerClassName="case-detail-accordian-header"
                  subContainerClassName="case-detail-accordian-header-status"
                  headerCount={processNO}
                  headerText={title}
                  status={isReject ? accordionStatus.REJECTED : isCompleted ? accordionStatus.COMPLETED : accordionStatus.OPEN}
                  targetId={targetId}
              />
              <AccordionBody                   
                accordionId={targetId}
                className="case-detail-accordian-body-con">
                  <Card>
                    {data_description && 
                    data_description?.documents?.length > 0 && 
                    Object.keys(data_description).length > 0 ? (
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                          <Col md={6}>
                            <div className="case-detail-accordian-body">
                                <div>{"Description"}</div>
                                <p className="case-detail-accordian-body-value">
                                    {data_description?.description || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Types of Goods/Services"}</div>
                                <p>
                                    {data_description?.goodType || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Applicant's name"}</div>
                                <p>
                                    {data_description?.applicantName || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Applicant's address"}</div>
                                <p>
                                    {data_description?.applicantAddress  || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Country"}</div>
                                <p>
                                    {data_description?.countryName || "-"}
                                </p>
                            </div>
                            
                            <div className="case-detail-accordian-body">
                                <div>{"Step Fee"}</div>
                                <p>
                                    {`$${data_description?.amount}`  || "-"}
                                </p>
                            </div>
                            {!isCompleted && (<div className='bg-white p-3 pt-4 case-details-accordian-date-field'>
                                <Label>Fee Deadline</Label>
                                <div className="mb-3">
                                    <TextInput
                                        name={"feeDeadline"}
                                        type="date"
                                        className="hide-icon"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.feeDeadline}
                                        autoFocus
                                        iconFile={DateIcon}
                                        disabled={isCompleted || isFormTwoVisible}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={"feeDeadline"}
                                     />
                                </div>                  
                            </div> )}
                          </Col>
                          <Col md={6}>
                            <div className="header">
                                  Commercial Registration/Passport
                              </div>
                            {data_description?.documents?.map((el, idx) => {
                                if(el?.documentType === "CommercialRegistrationPassport"){
                                    return (
                                        <div key={idx}>
                                            <TrademarkDetail
                                                documentData={el?.media}                                                
                                                documentViewerHandler={documentViewerHandler}
                                            />
                                        </div>
                                    )
                                }
                            })}

                            <div className="header">
                                  Trademark
                            </div>

                            {data_description?.documents?.map((el, idx) => {
                                if(el?.documentType === "Trademark"){
                                    return (
                                        <div key={idx}>
                                            <TrademarkDetail
                                                documentData={el?.media}
                                                documentViewerHandler={documentViewerHandler}
                                            />
                                        </div>
                                    )
                                } 
                                    
                            })}
                          </Col>
                      </Row>

                      {!isCompleted && (
                        <>
                      <hr />
                      <Row>
                          <div className="accordian-body-footer-btn">
                              <CustomButton
                                  title={buttonTitle.MOVE_TO_NEXT_STEP}
                                  className="trademark-body-footer-accept-button shadow-none me-3"
                                  type={"submit"}
                                  disabled={isFormTwoVisible }
                              />
 
                              <CustomButton
                                  title={buttonTitle.REJECT}
                                  className="trademark-body-footer-reject-button shadow-none"
                                  color="#E64A4A"
                                  onClick={() => {
                                      setRejectionSuccessModal(true);
                                  }}
                                  disabled={isSubmittingStep}
                              />
                          </div>
                      </Row>
                      </>
                       )} 
                    </form>
                   ):(<><p className="data-not-found-message">Data Not Found</p></>)}
                  </Card>
                  { isReject ?
                    (<>
                    <Card className="p-3 pt-0">
                        <Row>
                        <hr />
                        <Col className="pt-2">
                            <p className="rejection-reason"><span className="rejection-typo-heading">Rejection reason: </span>{rejectionReason || "-"}</p>
                        </Col>                          
                        </Row>
                    </Card>
                    </>) : null
                    }
                    <DocumentViewerModal
                    modalActivator={isPdfViewerModalActive}
                    modalCloseHandler={pdfViewerModalCloseHandler}
                    modalData={pdfDataLink}
                    isDataFetching={false}
                    />
                    <CaseRejection
                        modalCloseHandler={rejectionModalCloseHandler}
                        modalActivator={rejectionSuccessModal}
                        stepId={data_description}
                        setIsRefetchingRequired={refetchStepsAPIHandler}
                    />
              </AccordionBody>
          </AccordionItem>
      </UncontrolledAccordion>
    </div>
    </>
  )
}

// handling prop types
FilingCompleted.propTypes = {
  openAccord: PropTypes.string,
  toggleButtonHandler: PropTypes.func,
  data_description: PropTypes.any,
  targetId: PropTypes.string,
  status: PropTypes.bool,
  resetSuccessModal: PropTypes.func,
  nextStepHandler: PropTypes.func,
  setRejectionSuccessModal: PropTypes.func,
  processNO: PropTypes.string
}
